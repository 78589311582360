import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import NavBar from '../../../Components/Navbar/NavBar'
import Input from '../../../Components/utils/Input'
import validateEmail from '../../../Validation/Email'
import validatePassword from '../../../Validation/Password'
import googleIcon from '../../../Assets/Icons/google.svg'
import appleIcon from '../../../Assets/Icons/apple.svg'
import facebookIcon from '../../../Assets/Icons/facebook.svg'
import SmsIcon from '../../../Assets/Icons/sms.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { auth, FirebaseAuthServiceErrorCode, getJWTToken, loginWithPhoneNumber, sendPasswordReset, signInWithApple, signInWithFacebook, signInWithGoogle } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { ConfirmationResult, getAuth, inMemoryPersistence, signInWithEmailAndPassword, indexedDBLocalPersistence, browserSessionPersistence } from 'firebase/auth'
import ApiService from '../../../Services/ApiService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { validatePhoneNumber } from '../../../Validation/Phone'
import Code from './Phone/code'
import TeachrRepository from '../../../Repositories/TeachrRepository'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ClientRepository from '../../../Repositories/ClientRepository'
import Functions from '../../../Helpers/Functions'
import Loader from '../../../Components/utils/Loader'
import { Checkbox } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import PunctualCourseRepository from '../../../Repositories/PunctualCourseRepository'
import NavbarHomePage from '../../../Components/Navbar/NavbarHomePage'

function Login() {
    const dispatch = useDispatch()
    // const generalTeachr = useSelector((state: any) => state.teachr)
    const MySwal = withReactContent(Swal)
    let timerInterval: any
    const navigate = useNavigate()
    let restEmail = ''
    let phone = ''
    const [user, loadUser] = useAuthState(auth)
    const [remember, setRemember] = useState(false)
    const [loading, setLoading] = useState(false)
    // useEffect(()=>{
    //     if (generalTeachr) {
    //     }
    // },[generalTeachr])
    useEffect(() => {
        if (loadUser) {
            dispatch({
                type: 'SHOW_DASHBOARD_AICI_LOADER', payload: ""
            })
        }else{
            if (user) {
                (async () => {
                    setLoading(true)
                    Swal.close()
                    const jwtToken = await getJWTToken(user)
                    ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
                    TeachrRepository.getCurrentTeachr().then((response) => {
                        dispatch({
                            type: 'SET_TEACHR_INFO',
                            payload: {
                                teachr: response,
                                authorization: "teachr " + jwtToken
                            }
                        })
                        PunctualCourseRepository.getPunctualCourseByTeachr(response.id!).then((courses)=>{
                            if (courses.length  > 0) {
                                navigate("/teachr/dashboard/")
                                
                            }
                            else if (response.isFirstCourse) {
                                navigate("/teachr/dashboard/")
                            } else if(response.isAE && response.signSap) {
                              navigate("/teachr/dashboard/");
                            }else if(response.isAE && !response.signSap){
                                navigate("/teachr/profile/complete");
                            }else if (response.isValidated && !response.isAE && !response.signSap) {
                                navigate("/teachr/profile/complete");
                            } else {
                                navigate("/teachr/cours");
                            }
                            dispatch({
                                type: 'HIDE_LOADER', payload: ""
                            })
                            setLoading(false)
                        })
                      
                    }).catch(() => {
                        ApiService.disableAuth()
                        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
                        ClientRepository.getCurrentClient().then((response) => {
                            navigate("/user/response")
                            dispatch({
                                type: 'HIDE_LOADER', payload: ""
                            })
                            setLoading(false)
                        }).catch(() => {
                            ApiService.disableAuth()
                            navigate("/teachr/register/next")
                            dispatch({
                                type: 'HIDE_LOADER', payload: ""
                            })
                            setLoading(false)
                        })
    
                    })
                })()
            }else{
                setLoading(false)
                dispatch({
                    type: 'HIDE_LOADER', payload: ""
                })
            }
        }
        
    }, [user, loadUser]);
    const [showPass, setShowPass] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const getEmail = (value: string) => {
        setForm({
            email: value,
            password: form.password
        })
    }
    // const getPassword = (value: string) => {
    //     setForm({
    //         email: form.email,
    //         password: value
    //     })
    // }
    const [errors, setErrors] = useState({
        'email': '',
        'password': ''
    })
    const validate = () => {

        let erremail = true
        let errpass = true
        if (!validateEmail(form.email).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = false
        }
        if (!validatePassword(form.password, form.password).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = false
        }


        if (errpass || erremail)
            return false
        else return true
    }
    const login = async () => {
        const auth = getAuth()
        signInWithEmailAndPassword(auth, form.email, form.password).catch((error: any) => {
            document.getElementById('teachr-loader')?.classList.add('hidden')
            switch (error.code) {
                case FirebaseAuthServiceErrorCode.USER_NOT_FOUND:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.WRONG_PASSWORD:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.USER_DISABLED:
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Votre compte a été désactié!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                default:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "quelque chose s'est mal passé!",
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
            }
        })
    }
    const handleSubmit = (event: any) => {
        setLoading(true)
        event.preventDefault()
        if (validate()) {
            document.getElementById('teachr-loader')?.classList.remove('hidden')

            if (remember) {
                auth.setPersistence(indexedDBLocalPersistence).then((res)=>{
                    login()
                })
                
            } else {
                auth.setPersistence(browserSessionPersistence).then((res) => {
                  login();
                });
            }
            // login()

        }
        setLoading(false)

    }

    const showPhoneLoginWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            customClass: {
                container: 'm-0'
            },
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Téléphone</label>
                            <input type="text" onChange={(e) => phone = e.target.value} className='block w-full bg-transparent border-none focus:outline-none' placeholder='Ex. 06 XX XX XX XX' />
                        </div>
                        <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 mx-auto my-8 text-sm uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first whitespace-nowrap md:text-base' >ENVOYER LE CODE DE VERIFICATION</Button>
                    </div>
                </div>,
            preConfirm: (confirmationCode) => {
                if (validatePhoneNumber(phone) === '') {
                    return loginWithPhoneNumber(Functions.formatPhoneNumber(phone)).then((response) => {
                        return response
                    }).catch((error) => {
                        Swal.showValidationMessage("Nous rencontrons actuellement un problème technique. Veuillez re-essaayer plus tard ou nous contacter par mail à l'adresse support@teachr.fr")
                    })

                } else {
                    return Swal.showValidationMessage(validatePhoneNumber(phone))
                }

            },
        }).then((result) => {
            if (result.isConfirmed) {
                showPhoneCodeWidget(result.value!)
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showPhoneCodeWidget = (code: ConfirmationResult) => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <Code phone={phone} code={code} />
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showResetPasswordWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>MOT DE PASSE OUBLIé</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <p className='font-semibold text-first text-[20px]'>Entrez l’adresse mail associée à votre compte.
                            Vous allez recevoir un lien pour réinitialiser votre mot de passe </p>
                        {errors.email === '' ? null : <p className='py-2 font-bold text-red-600'>{errors.email}</p>}
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Email</label>
                            <input onChange={(e) => restEmail = e.target.value} type="text" className='block w-full bg-transparent border-none focus:outline-none' placeholder='mail@mail.com' />
                        </div>
                        <Button onClick={() => { Swal.enableInput(); Swal.clickConfirm() }} className='block px-8 py-2 my-8 uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first w-max' >envoyer le lien</Button>
                    </div>
                </div>,
            preConfirm: () => {
                if (validateEmail(restEmail).isValid) {
                    return sendPasswordReset(restEmail).catch((error) => {
                        if (error.code === FirebaseAuthServiceErrorCode.USER_NOT_FOUND) {
                            Swal.showValidationMessage('Aucun compte associé à cette adresse mail')
                        }
                    })
                }
                Swal.showValidationMessage(validateEmail(restEmail).message)
            },
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    icon: 'success',
                    title: 'Envoyé!',
                    text: "Nous vous avons envoyé un e-mail de réinitialisation du mot de passe. Veuillez consulter votre boîte e-mail",
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    return (
      <div className="w-screen overflow-x-hidden md:h-screen md:overflow-y-auto">
        <Loader />
        {/* <NavBar theme="light" /> */}
        <NavbarHomePage theme="light" />
        <div className="flex lg:gap-8 lg:pl-[140px] px-4 md:px-0 md:pl-8 mt-24">
          <div className="w-full md:w-1/2 space-y-4 mt-2  md:px-10 pb-2">
            <h1 className="xl:leading-[44px] xl:text-[32px] leading-[15px] text-[22px] text-first font-extrabold">
              CONNEXION
            </h1>
            <div className="flex flex-col gap-4 md:gap-y-8 /md:w-3/4">
              <div>
                {errors.email === "" ? null : (
                  <p className="py-2 font-bold text-red-600">{errors.email}</p>
                )}
                <Input
                  callback={getEmail}
                  label="Email"
                  inset="true"
                  placeholder="exemple@exemple.com"
                  customClass="bg-bg-input-light p-4 xl:text-lg  font-semibold"
                  onChange={(e: any) =>
                    setForm({ email: e.target.value, password: form.password })
                  }
                />
              </div>
              <div>
                {errors.password === "" ? null : (
                  <p className="py-2 font-bold text-red-600">
                    {errors.password}
                  </p>
                )}
                <div className="relative w-full p-4 py-4 rounded-md bg-bg-input-light xl:text-lg  font-semibold">
                  <label htmlFor="">Mot de passe</label>
                  <input
                    type={showPass ? "text" : "password"}
                    className="block w-full bg-transparent border-none focus:outline-none"
                    onChange={(e: any) =>
                      setForm({ email: form.email, password: e.target.value })
                    }
                    placeholder="********"
                  />
                  <span
                    className="absolute cursor-pointer top-1/3 right-3"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? (
                      <VisibilityOffIcon className="text-normal" />
                    ) : (
                      <VisibilityIcon className="text-normal" />
                    )}
                  </span>
                </div>
                <div className="flex items-center gap-2 py-4">
                  <Checkbox
                    onChange={() => setRemember(!remember)}
                    className="p-0"
                  />
                  <a href="#" className="text-black " rel="noreferrer">
                    Se souvenir de moi lors de ma prochaine connexion
                  </a>
                </div>
              </div>
            </div>
            <div className="/pt-4">
              <p className="text-normal">
                Mot de passe oublié ?{" "}
                <Link
                  to="#"
                  onClick={() => showResetPasswordWidget()}
                  className="ml-2 font-bold underline text-first"
                >
                  Cliquez ici
                </Link>
              </p>
            </div>
            <div className=" /md:w-3/4 text-[#808080] /md:pt-8 py-4">
              <div className="flex items-center justify-between gap-4 text-normal">
                <hr className="w-full" />
                <span className="w-full whitespace-nowrap">
                  Ou se connecter avec :
                </span>
                <hr className="w-full" />
              </div>
              <div className="flex justify-center gap-8 pt-4 md:gap-14">
                <img
                  src={googleIcon}
                  onClick={signInWithGoogle}
                  alt=""
                  className="w-[43px] cursor-pointer"
                />
                <img
                  src={appleIcon}
                  onClick={signInWithApple}
                  alt=""
                  className="w-[43px] cursor-pointer"
                />
                <img
                  src={facebookIcon}
                  onClick={signInWithFacebook}
                  alt=""
                  className="w-[43px] cursor-pointer"
                />
                <img
                  src={SmsIcon}
                  alt=""
                  onClick={() => showPhoneLoginWidget()}
                  className="w-[43px] cursor-pointer"
                />
              </div>
            </div>
            <div className="py-4 md:py-4">
              <div className="flex justify-between gap-x-4">
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="block pb-2 text-sm font-bold text-first lg:text-md whitespace-nowrap pl-[15px]"
                  >
                    Vous n'avez pas de compte ?
                  </label>
                  <Link
                    to="/teachr/register"
                    className="  block text-center rounded-full py-2 border-solid border-[1px] border-first text-first uppercase px-4 w-full font-bold"
                  >
                    JE M'INSCRIS
                  </Link>
                </div>
                <div className="self-end w-full">
                  <Button
                    className="rounded-full py-2 text-white bg-first border-solid border-[1px] border-first  uppercase px-4 w-full font-extrabold font-nunito disabled:opacity-30"
                    disabled={loading}
                    onClick={(e) => handleSubmit(e)}
                  >
                    CONNEXION
                  </Button>
                </div>
              </div>

              {/* <div className=' text-radio items-center lg:text-[21px] font-semibold py-8'>
                            <Link to="" className='underline'>Continuer en tant que visiteur <VisibilityIcon /></Link>

                        </div> */}
            </div>
          </div>
          <div className="hidden w-1/2 bg-left-top bg-no-repeat md:block bg-login-il translate-y-14 translate-x-10"></div>
        </div>
        <div id="recaptcha-container"></div>
      </div>
    );
}

export default Login