
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import { Button } from '@mui/material';
import ValidateIban from "../../../../../Validation/Iban";
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
import Functions from '../../../../../Helpers/Functions';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

export default function ValidIban(props: any) {
    const navigate = useNavigate()
    const { handleOpen, handleCloses, opens, teachr } = props;
    const [iban, setIban] = React.useState("")
    const [load, setLoad] = React.useState(false)
    const [err, setErr] = React.useState({ iban: "null" });
    let timerInterval: any;

    const validate = () => {
        let error = true;
        if (!ValidateIban(iban).isValid) {
            setErr({ iban: ValidateIban(iban).message })
            error = true
        } else {
            setErr({ iban: ValidateIban(iban).message });
            error = false
        }
        if (error) {
            return false
        } else {
            return true
        }
    }
    const handleValidate = () => {
        if (validate()) {
            setLoad(true)
            teachr.iban = iban.replace(/ /g, "");
            if (teachr && teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity["@id"];
            }
            if (teachr && teachr.proofIdentity2) {
                teachr.proofIdentity2 = teachr.proofIdentity2["@id"];
            }
            if (teachr && teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE["@id"];
            }
            if (teachr && teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap["@id"];
            }
            if (teachr && teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat["@id"];
            }
            TeachrRepository.updateTeachr(teachr).then((res) => {
                handleCloses()
                navigate("/teachr/dashboard/declare");
                setLoad(false)
                setIban("")
            }).catch((err) => {
                handleCloses()
                setLoad(false)
                if(err._description){
                    Swal.fire({
                      icon: "error",
                      title: "Erreur",
                      text: "Veuillez renseigner une IBAN valide",
                      timer: 5000,
                      willClose: () => {
                        clearInterval(timerInterval);
                        handleOpen()
                      },
                    });
                }else{
                    Swal.fire({
                      icon: "error",
                      title: "Erreur",
                      text: err._description,
                      timer: 5000,
                      willClose: () => {
                        clearInterval(timerInterval);
                        handleOpen();
                      },
                    });
                }
            });
        }
    }
    const handleLock = () => {
        handleCloses()
        setIban("")
    }
    return (
        <React.Fragment>
            <Dialog open={opens} onClose={handleOpen}>
                <div className='p-4 font-nunito'>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>Renseignements supplémentaires</p>
                    </div>
                    <div className="py-8 text-left ">
                        <p className='text-sm text-normal'>Pour déclarer vos cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                            Une fois complétées, vous pourrez déclarer à autant de cours que vous souhaitez !
                        </p>
                        <div className='flex flex-col w-full gap-4 pt-4'>
                            <p className={`${err.iban === "null" ?'hidden' : err.iban ? 'block' : 'hidden'} text-third text-base md:text-base`}>{ValidateIban(iban).message}</p>
                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                <p className='/mx-2'>Iban</p>
                                <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none uppercase' onChange={(e) => setIban(e.target.value)} defaultValue={Functions.addEspacesIBAN(iban)} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                            </div>
                        </div>
                        <div className='flex gap-x-2 pt-4 lg:pt-8'>
                            <Button onClick={() => handleLock()} className='block bg-transparent rounded-full w-full lg:px-14 px-8 text-first  border-solid font-bold border-2 border-first  uppercase' >Fermer</Button>
                            <Button onClick={() => handleValidate()} className='block rounded-full w-full lg:px-14 px-8 text-white hover:text-first  border-solid font-extrabold border-2 border-first bg-first hover:bg-white uppercase' >{load ? "Chargement...":"Valider"}</Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    );
}