import { useEffect, useRef, useState } from "react";
import PunctualCourseRepository from "../../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../../Objects/models/PunctualCourseDashboard";
import ComponentBodyTablePunctualCourse from "./ComponentBodyTablePunctualCourse";
import ponct_dash from "../../../../../../../../Assets/Images/ponct_dash.svg"
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
// import { RadioGroup } from "@mui/material";
import React from "react";
import Pagination from "../Pagination";
import Switch from "@mui/material/Switch";


const ComponentPoncual = (props: any) => {
  const [course, setCourse] = useState<PunctualCourseDashboard[]>();
  const { handleLoad, isHere, isEdit, isDelete, handleEdit, load, handleLockDelete } = props
  // const [value, setValue] = useState('');
  const [teachr, setTeachr] = useState<any>();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  // const radioGroupRef = useRef<HTMLElement>(null);
  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setValue((event.target as HTMLInputElement).value)
  // }
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10); // Nombre de lignes par page

  const filteredCourses = course?.filter((cours: PunctualCourseDashboard) => {
    const courseStart = moment(cours?.startDatetime);
    return courseStart; // '[]' inclut les limites
  });
  // Trier les cours filtrés par date de début croissante (ou décroissante si nécessaire)
  // const sortedCourses = filteredCourses?.sort(function (a: PunctualCourseDashboard, b: PunctualCourseDashboard) {
  //   if(moment().toDate() === moment(b.startDatetime).toDate()){
  //     return 1;
  //   }else{
  //     return moment(b.startDatetime).diff(moment(a.startDatetime));
  //   }
  // });
  const sortedCourses = filteredCourses?.sort(function (a: PunctualCourseDashboard, b: PunctualCourseDashboard) {
  const today = moment().startOf('day');
  const aDate = moment(a.startDatetime).startOf('day');
  const bDate = moment(b.startDatetime).startOf('day');

  if (bDate.isSame(today)) {
    return 1;
  } else if (aDate.isSame(today)) {
    return -1;
  } else {
    return moment(b.startDatetime).diff(moment(a.startDatetime));
  }
});


  // Pagination
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = sortedCourses?.slice(indexOfFirstData, indexOfLastData);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachr) => {
      setTeachr(teachr)
      if (teachr.id) {
        PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
          (course) => {
            setCourse(course);
          }
        );
      }
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (load) {
        if (teachr?.id) {
          PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
            (course) => {
              setCourse(course);
            }
          );
          // handleLoad(false);
        }
      }
    }, 200);
  }, [load, teachr]);

  const showActiveCourse = () => {
    return (
      <div className={ "xl:overflow-hidden overflow-x-auto w-[100%] /h-[100%] bg-white lg:shadow-simulator-dashboard rounded-lg list-teacher /lg:rounded-[30px] border-[2px] border-first "}>
        {!currentData ? (
          <div className="overflow-y-auto w-full relative  h-[450px] /md:mt-6 /px-2">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        ) : currentData.length === 0 ? (
          <div className="  md:w-full relative list-teacher h-[450px] mt-6 px-2 py-2 bg-white">
            <div className="mx-auto">
              <div className="md:w-4/5 md:mt-8  mx-auto ">
                <p className="text-[#4d4d4d] text-center lg:text-2xl md:text-lg text-base font-bold mx-auto">
                  Afin de recevoir un paiement via notre plateforme,
                  Veuillez déclarer votre premier cours, ainsi que tout
                  autre cours que vous avez déjà dispensé.
                </p>
              </div>
              <div className="mx-auto md:mt-12 mt-2 md:w-1/2">
                <img
                  src={ponct_dash}
                  alt="ponct_dash"
                  className="mx-auto"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="relative  align-middle h-full inline-block min-w-full bg-white shadow-dashboard pt-3 rounded-bl-lg rounded-br-lg">
            <div className="/xl:h-[80%]">
              <table className="w-full">
                <thead>
                  <tr className="border-b-2 border-first">
                    {isHere && <th className="px-3 py-3 " />}
                    <th className="px-2 py-2 w-1/5 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">Période de cours</th>
                    <th className="pr-12 py-2 w-1/7 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">Durée</th>
                    <th className="px-2 py-2 w-1/6 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">Élève</th>
                    <th className="px-2 py-2 w-1/6 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">Matière</th>
                    <th className="px-2 py-6 w-1/6 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider"> <span className="hidden md:flex">Type de cours</span> <span className="md:hidden">Cours</span></th>
                    <th className="px-2 py-2 w-1/6 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">{checked ? 'Montant Facturé':'Rémunération'} <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}/></th>
                    <th className="px-2 py-2 w-1/6 font-bold text-left /md:text-base text-sm leading-4 text-first tracking-wider">Statut</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {currentData?.map(
                    (punctual: PunctualCourseDashboard, key) => (
                      <ComponentBodyTablePunctualCourse
                        punctual={punctual}
                        checked={checked}
                        handleLoad={handleLoad}
                        load={load}
                        isHere={isHere}
                        handleLockDelete={handleLockDelete}
                        isEdit={isEdit}
                        isDelete={isDelete}
                        handleEdit={handleEdit}
                        key={key}
                      />
                    )
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              pages={Math.ceil(course?.length! / dataPerPage)}
              currentPage={currentPage}
              onPageChange={paginate}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {showActiveCourse()}
    </>
    // <div className=" /xl:h-[85%] /lg:[77%] /h-[50%] border-2 border-green-500 mt-4">
    // </div>
  );
};

export default ComponentPoncual;
