import Teachr from "./Teachr";

export default class TeachrContact  {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

        
    readonly id?: Number;

    readonly lastModificationDatetime?: Date;

    readonly creationDatetime?: Date;
    readonly key? : string

    step : Number
    status? : boolean
    studentName?: string
    level?: number
    firstName?: string
    name?: string
    birthName?: string
    mail: string
    phoneNumber?: string
    gender?: string
    birthDay?: string | null
    isBornFrance?: boolean
    zipCode?: string 
    city?: string
    address?: string
    country?: string
    iban?: string
    bic?: string
    teachr: string
    acceptMarketingEmail?: boolean
    isNotify?: boolean
    constructor(
        step: Number,
        mail: string,
        teachr: string
    ) {
        this.step = step
        this.mail = mail
        this.teachr = teachr
    }

}
