import { Button, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import DateInput from '../../../../Components/Admin/DateInput';
import Input from '../../../../Components/Admin/Input';
import SelectComponent from '../../../../Components/Admin/Select';
import SelectCountry from '../../../../Components/Admin/SelectCountry';
import AddressComponent from '../../../../Components/utils/Address';
import RadioButton from '../../../../Components/utils/RadioButton';
import StepperStore from '../../../../LocalForage/StepperStore';
import AiciSetupInterface from '../../../../Objects/interfaces/AiciSetup';
import { Address } from '../../../../Objects/models/Address';
import validateDateAici from '../../../../Validation/DateAici';
import validateZipCode from '../../../../Validation/zipCode';
import Layout from './Layout';
import SelectCity from '../../../../Components/Admin/SelectCity';
moment().locale('fr')
const Second = (props: any) => {
    const [save, setSave] = useState<AiciSetupInterface>()
    const [address, setAddress] = useState<Address>()
    const [gender, setGender] = useState('')
    const [birthDay, setBirthDay] = useState('')
    const [isBornFrance, setIsBornFrance] = useState<boolean | null>(null)
    const [country, setCountry] = useState('')
    const [birthName, setBirthName] = useState('')
    const [zipCode, setZipcode] = useState('')
    const [city, setCity] = useState('')
    const [errors, setErrors] = useState({
        zipCode: false,
        birthDay: false
    })
    useEffect(() => {
        StepperStore.getItem('dashboard_setup').then((response: any) => {
            if (response) {
                setSave(response)
                if (response.Parent.second) {
                    if (response.Parent.second.isBornFrance !== null) {
                        setIsBornFrance(response.Parent.second.isBornFrance)
                    }
                    if (response.Parent.second.zipcode) {
                        setZipcode(response.Parent.second.zipcode)
                    }
                    if (response.Parent.second.city) {
                        setCity(response.Parent.second.city)
                    }
                    if (response.Parent.second.address) {
                        setAddress(response.Parent.second.address)
                    }
                    if (response.Parent.second.country) {
                        setCountry(response.Parent.second.country)
                    }
                    if (response.Parent.second.birthDay) {
                        setBirthDay(response.Parent.second.birthDay)
                    }
                    if (response.Parent.second.gender) {
                        setGender(response.Parent.second.gender)
                    }
                    if (response.Parent.second.birthName) {
                        setBirthName(response.Parent.second.birthName)
                    }
                }
            }
        })
    }, [])
    const getGender = (value: string) => {
        setGender(value)
    }
    const getBirthday = (value: string) => {
        setBirthDay(value)
    }
    const getBirthName = (value: string) => {
        setBirthName(value)
    }
    const getZipCode = (value: string) => {
        setZipcode(value)
    }
    const getCity = (value: string) => {
        setCity(value)
    }
    const getPlaceId = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            const addressObj = new Address(
                response[0].place_id,
                response[0].address_components[0].long_name,
                response[0].address_components[1].long_name,
                response[0].address_components[2].long_name,
                response[0].address_components[3].long_name,
                response[0].address_components[4].long_name,
                response[0].address_components[5].long_name,
                response[0].address_components[6].long_name,
                response[0].geometry.location.lat().toString(),
                '',
                response[0].formatted_address,
                "",
                false)
            addressObj.sessionToken = ''
            setAddress(addressObj)
        })

    }

    const getCountry = (value: string) => {
        setCountry(value)
    }
    const nextStep = () => {
        let screenState: AiciSetupInterface = {
            currentStep: 3,
            key: '',
            paiment: {
                bic: null,
                iban: null
            },
            Parent: {
                first: {
                    firstName: null,
                    name: null,
                    mail: '',
                    telephone: null,
                },
                second: {
                    address: address!,
                    birthDay: birthDay,
                    country: country,
                    gender: gender,
                    isBornFrance: isBornFrance,
                    zipCode: zipCode,
                    city: city,
                    birthName: birthName
                }
            },
            student: {
                firstName: null,
                level: null
            },
        }
        if (isBornFrance) {
            screenState.Parent.second.country = null
        } else {
            screenState.Parent.second.zipCode = null
        }
        if (validate()) {
            if (save && screenState) {
                if (save.Parent.first && save.student) {
                    screenState.Parent.first = save.Parent.first
                    screenState.student = save.student
                    screenState.paiment = save.paiment
                    StepperStore.setItem('dashboard_setup', screenState).then(() => props.nextStep(3))
                }
            }
        }
    }
    const validate = () => {
        let valid = {
            zipCode: false,
            birthDay: false
        }
        if (birthDay !== "") {
            valid = {
                zipCode: valid.zipCode,
                birthDay: !validateDateAici(birthDay).isValid
            }
        }
        if (zipCode !== "" && zipCode) {
            valid = {
                zipCode: !validateZipCode(zipCode,city).isValid,
                birthDay: valid.birthDay
            }
        }
        setErrors(valid)
        if (!valid.birthDay && !valid.zipCode) {
            return true
        }
        return false

    }

    const prevStep = () => {
        if (save) {
            props.nextStep(1)
        }

    }
    const renderBornFrance = () => {
        if (isBornFrance) {
            return (
                <div className="grid grid-cols-1 gap-8 md:gap-y-4 md:grid-cols-2">
                    <div className="w-full">
                        <p className={!errors.zipCode ? 'hidden' : "block text-third"}>{validateZipCode(zipCode,city).message}</p>
                        <Input callBack={getZipCode} defaultValue={save?.Parent.second.zipCode} field='zipcode' label='Code postal de naissance' placeholder='Ex: 75001' />
                    </div>
                    <div className="w-full">
                        {city !== "" ?
                            <SelectCity value={city} callBack={getCity} zipCode={zipCode} />
                            :
                            <SelectCity callBack={getCity} zipCode={zipCode} />}
                    </div>
                    <div className="w-full">
                        <div className='relative px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
                            <label className='block lg:text-base'>Adresse de facturation</label>
                            {address ? <AddressComponent default={address.formattedAddress} getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" /> : <AddressComponent getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" />}
                        </div>
                    </div>
                </div>
            )
        } else if (isBornFrance === false) {
            return (
                <div className='flex flex-col gap-8 md:flex-row'>
                    <div className="w-full">
                        {country !== "" ?
                            <SelectCountry value={country} callBack={getCountry} />
                            :
                            <SelectCountry callBack={getCountry} />}
                    </div>
                    <div className="w-full">
                        <div className='relative px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
                            <label className='block lg:text-base'>Adresse de facturation</label>
                            {address ? <AddressComponent default={address.formattedAddress} getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" /> : <AddressComponent getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" />}
                        </div>
                    </div>
                </div>


            )
        }
        return (
            <div className='flex flex-col gap-8 md:flex-row'>
                <div className="w-full">
                    <div className='relative px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
                        <label className='block lg:text-base'>Adresse de facturation</label>
                        {address ? <AddressComponent default={address.formattedAddress} getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" /> : <AddressComponent getPlaceId={getPlaceId} placeholder="Ex:  2 rue de la Seine, 94004" />}
                    </div>
                </div>
                <div className="w-full">
                </div>
            </div>
        )

    }

    return (
        <div className='w-full'>
            <Layout
                next={<Button onClick={() => nextStep()} className='w-full py-2 font-extrabold text-white uppercase rounded-full px-14 bg-first md:w-max md:text-base border-solid border-2 border-first' >SUIVANT</Button>}
                prev={<Button onClick={() => prevStep()} className='w-full py-2 font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first px-14 md:w-max border-first md:text-base' >précédent</Button>}
            >
                <div className='pb-6 md:w-3/4'>
                    <h1 className='pb-8 text-lg font-bold lg:text-2xl md:text-xl text-first'>Informations de votre client (2/2) :</h1>
                    <div className='flex flex-col gap-8'>
                        <div className="grid grid-cols-1 gap-8 md:items-end md:grid-cols-2">
                            <div className='w-full'>
                                <SelectComponent
                                    defaultValue={save?.Parent.second.gender}
                                    field='gender'
                                    placeholder='Homme / Femme'
                                    callBack={getGender}
                                    label="Civilité"
                                    data={[
                                        { label: "Une femme", value: 2 },
                                        { label: "Un homme", value: 1 },
                                    ]}
                                />
                            </div>
                            <div className='w-full'>
                                <p className={!errors.birthDay ? 'hidden' : "block text-third"}>{validateDateAici(birthDay).message}</p>
                                {save && <DateInput defaultValue={save.Parent.second.birthDay ? moment(save.Parent.second.birthDay, 'L') : null} field='birthday' callBack={getBirthday} />}
                            </div>
                            {gender === "2" &&
                                <div className="w-full">
                                    <Input field='birthName' defaultValue={birthName !== '' ? birthName : null} callBack={getBirthName} label='Nom de naissance' placeholder='Ex: DUPONT' />
                                </div>}
                        </div>
                        <div className='relative flex flex-col pb-8 md:gap-8 md:items-center md:flex-row'>
                            <label htmlFor="" className='text-base font-bold md:text-lg text-first whitespace-nowrap'>Pays de naissance : France</label>
                            <FormControl>
                                {save && <RadioGroup
                                    defaultValue={save.Parent.second.isBornFrance === null ? isBornFrance : save.Parent.second.isBornFrance}
                                    name="customized-radios"
                                    row
                                >
                                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="true" control={<RadioButton onClick={() => setIsBornFrance(true)} border='2px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="false" control={<RadioButton onClick={() => setIsBornFrance(false)} border='2px solid #0054A3' bg='#0054A3' />} label="Non" />
                                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="null" control={<RadioButton onClick={() => setIsBornFrance(null)} border='2px solid #0054A3' bg='#0054A3' />} label="Ne sait pas" />

                                </RadioGroup>}
                            </FormControl>
                            <div className='absolute bottom-3'><p className='text-grey'>Information nécessaire demandée par l'URSSAF</p></div>
                        </div>

                        {renderBornFrance()}
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Second;