import { useEffect, useState } from "react";
import logo_degrade from "../../Assets/Images/logo_bleu.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List } from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Services/FirebaseService";
import ApiService from "../../Services/ApiService";
import { signOut } from "firebase/auth";
import Input from "../Admin/Input";
import TeachrRepository from "../../Repositories/TeachrRepository";
import TeachrContactRepository from "../../Repositories/TeachrContactRepository";
import TeachrContact from "../../Objects/models/TeachrContact";
import ClientRepository from "../../Repositories/ClientRepository";
import Client from "../../Objects/models/Client";
import HydraError from "../../Objects/models/HydraError";
import ChildRepository from "../../Repositories/ChildRepository";
import StepperStore from "../../LocalForage/StepperStore";

const NavBarCominsoon = (props: any) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [showAici, setShowAici] = useState(false)
  const [invitationCode, setInvitationCode] = useState("")
  const [client, setClient] = useState<Client>()
  const [child, setChild] = useState<any>()
  const getInvitationCode = (value: string) => {
    setInvitationCode(value)
  }

  const handleSubmit = () => {
    (async () => {
      const addresse: any = client?.addresses[0];
      const teachr = await TeachrRepository.fetchTeachrByCode(invitationCode)
      if (teachr && client && client.mail && teachr.id) {
        const teachrContact = new TeachrContact(0, client.mail, `/teachrs/${teachr.id}`)
        teachrContact.firstName = client.firstName
        teachrContact.name = client.lastName
        teachrContact.phoneNumber = client.phone
        if (child !== undefined) {
          teachrContact.studentName = child?.firstName
          teachrContact.level = child?.class
          StepperStore.setItem(user ? `teachrContact${user?.uid}` : "teachrContact", child);
        }
        if (client?.addresses.length > 0) {
          teachrContact.address = addresse['@id']
        }
        TeachrContactRepository.createTeachrContact(teachrContact).then((response) => {
          if (response) {
            navigate(`/client/complete-infos/${response.key}`)
            setShowAici(false)
          }
        }).catch((errors: HydraError) => {
          let sameMail = false
          errors.arrayInvalidAttributes.map((error) => {
            if (error.description && error.description.search("This value is already used.") > - 1) {
              sameMail = true
            }
          })
          if (sameMail) {
            TeachrContactRepository.fetchTeachrContactByMail(client.mail!).then((response) => {
              if (response) {
                navigate(`/client/complete-infos/${response.key}`)
                setShowAici(false)
              }
            })
          }
        })
      }
    })()
  }
  useEffect(() => {
    ClientRepository.getCurrentClient().then((response: any) => {
      setClient(response)
      ChildRepository.fetchChildById(response.children[0]?.id).then((child: any) => {
        setChild(child);
      })

    })
  }, [])
  const handleHome = () => {
    window.location.href = "https://teachr.fr";
  };
  const logout = () => {
    signOut(auth).then(() => {
      ApiService.disableAuth()
      handleHome()
    }).catch((error) => {
      // An error happened.
    });

  }

  return (
    <div className="bg-[#fff] lg:bg-transparent  lg:pb-0 pb-8">
      <Dialog
        fullWidth
        open={showAici}
        onClose={() => setShowAici(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "42px",
            position: "relative",
            overflow: "visible",
            boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)",
          },
        }}
      >
        <div className="flex flex-col p-4 md:p-4 gap-y-4">
          <DialogTitle id="alert-dialog-title">
            <h2 className="mt-10 font-extrabold text-center uppercase text-first lg:text-2xl">
              Accéder à l'avance immédiate <br /> du crédit d'impôt
            </h2>
          </DialogTitle>
          <DialogContent className="flex flex-col py-10 gap-y-8">
            <p className="font-semibold text-first lg:text-xl">
              Afin d'accéder à l'avance immédiate du crédit d'impôt, merci de
              bien vouloir renseigner le code de votre professeur particulier.
            </p>
            <Input
              field="invitationCode"
              callBack={getInvitationCode}
              label="Code de l'enseignant :"
              placeholder="XXXXXXXX"
            />
          </DialogContent>
          <DialogActions className="">
            <a
              href="#"
              className="block py-2 px-8 text-lg font-extrabold text-white rounded-full bg-first no-underline"
              onClick={handleSubmit}
            >
              Valider
            </a>
          </DialogActions>
        </div>
      </Dialog>
      <div className="flex items-center justify-between px-8 py-6 font-extrabold">
        <div className="">
          <img
            className="cursor-pointer //lg:w-[189px] w-[100px] //w-[84px]"
            onClick={() => logout()}
            src={logo_degrade}
            alt=""
          />
        </div>
        <div className="lg:hidden">
          <MenuIcon
            onClick={() => {
              setToggle(!toggle);
            }}
          ></MenuIcon>
        </div>
        <div className="hidden lg:block">
          <List
            sx={{
              display: "flex",
              gap: "0 20px",
              "& .Mui-selected": {
                boxShadow: "4px 4px 6px 1px rgba(0, 0, 0, 0.25)",
                color: "#FF724F !important",
                backgroundColor: "#fff !important",
              },
              "& .MuiListItem-root": {
                padding: "0",
                borderRadius: " 9999px",
                fontFamily: "Nunito",
              },
            }}
          >
            {client && !client.idClientTiersPrestation && (
              <Button
                onClick={() => setShowAici(true)}
                className="font-extrabold uppercase text-dark_blue"
              >
                Activer mon compte Avance immédiate
              </Button>
            )}
            <Button
              onClick={() => navigate("/user/formlayout")}
              className="font-extrabold uppercase text-dark_blue"
            >
              PRENDRE UN COURS
            </Button>

            {user && (
              <Button
                onClick={() => logout()}
                className="rounded-full -mt-2  bg-first font-semibold text-white"
              >
                DéCONNEXION
              </Button>
            )}
          </List>
        </div>
      </div>
      {toggle ? (
        <div className="flex flex-col items-center gap-2 lg:hidden lg:flex-row">
          {client && !client.idClientTiersPrestation && (
            <Button
              onClick={() => setShowAici(true)}
              className="font-extrabold uppercase text-dark_blue"
            >
              Activer mon compte Avance immédiate
            </Button>
          )}
          <Button
            onClick={() => navigate("/user/formlayout")}
            className="font-extrabold uppercase text-dark_blue"
          >
            PRENDRE UN COURS
          </Button>
          {user && (
            <Button
              onClick={() => logout()}
              className="rounded-full -mt-2  bg-first font-semibold text-white"
            >
              DéCONNEXION
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default NavBarCominsoon;
