import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/close-btn.png";
// import errorIcon from "../../../../Assets/Images/error.png";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@mui/material";
import RadioButton from "../../../utils/RadioButtonClient";
import { styled } from "@mui/material/styles";
import StepperStore from "../../../../LocalForage/StepperStore";
import validateAddresseAdd from "../../../../Validations/Addresse";
import { useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Address } from "../../../../Objects/models/Address";
import AddressRepository from "../../../../Repositories/AddressRepository";
import ClientRepository from "../../../../Repositories/ClientRepository";
import ApiService from "../../../../Services/ApiService";
import { auth, getJWTToken } from "../../../../Services/FirebaseService";
// import FormLocation from "../FormLocation";
// import { useNavigate } from "react-router-dom";
// import AddHomeIcon from "@mui/icons-material/Add";
// import LoaderContext from "../../../LoadingContext";

const BootstrapButton = styled(Button)({
  // boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  fontSize: "20px",
  fontWeight: "bold",
  color: "#0054A3",
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#F2F5FB",
  borderRadius: "15px",
  boxShadow: "none",
  fontFamily: ["nunito"].join(","),
  "&:hover": {
    backgroundColor: "#0054A3",
    color: "white",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F2F5FB",
    color: "#0054A3",
  },
  "&:focus": {
    boxShadow: "0 0 0 1px .2rem rgba(0,23,55,.5)",
  },
});

export default function CreateAddress(props) {
  // const Swal = require('sweetalert2')
  // let timerInterval: any
  const [opens, setOpens] = React.useState(false);
  // const navigate = useNavigate();
  const [error, setError] = useState({
    addresse: "null",
  });
  const handleClickOpen = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpens(false);
  };

  const [address, setAddress] = useState({
    googlePlaceId: "",
    streetNumber: "",
    streetName: "",
    city: "",
    department: "",
    region: "",
    country: "",
    zip: "",
    latitude: "",
    longitude: "",
    fomattedAddress: "",
  });
  // const [addressObj, setAddressObj] = useState();
  const [addresseObject, setAddressObject] = useState();
  const [user] = useAuthState(auth);
  const [isDefaults, setIsDefault] = useState(true);
  const [load, setLoad] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionalInformation, setOptionalInformation] = useState({
    houseCode: "",
    intercom: "",
    extraInformation: "",
  });
  const [dataAddresse, setDataAddresse] = useState();
  const getElementAddress = (value) => {
    geocodeByPlaceId(value).then((res) => {
      const address_components = res[0].address_components;

      setAddress({
        googlePlaceId: value,
        fomattedAddress: res[0].formatted_address,
        latitude: res[0].geometry.location.lat(),
        longitude: res[0].geometry.location.lng(),
        streetNumber:
          address_components.length === 6
            ? ""
            : address_components[0].long_name,
        streetName:
          address_components.length === 6
            ? ""
            : address_components[1].long_name,
        city:
          address_components.length === 6
            ? ""
            : address_components[2].long_name,
        department:
          address_components.length === 6
            ? ""
            : address_components[3].long_name,
        region:
          address_components.length === 6
            ? ""
            : address_components[4].long_name,
        country:
          address_components.length === 6
            ? ""
            : address_components[5].long_name,
        zip:
          address_components.length === 6
            ? ""
            : address_components[6].long_name,
      });
    });
  };
  useEffect(() => {
    const owner = "";
    const postGISPoint = "";
    const lastModificationDatetime = "";
    const creationDatetime = "";
    const isDeleted = false;
    const url = "";
    const isDefault = isDefaults;
    const houseCode = optionalInformation.houseCode;
    const extraInformation = optionalInformation.extraInformation;
    const intercom = optionalInformation.intercom;
    const addresseObjt = new Address(
      address.googlePlaceId,
      address.streetNumber,
      address.streetName,
      address.city,
      address.department,
      address.region,
      address.country,
      address.zip,
      address.latitude,
      url,
      address.fomattedAddress,
      owner,
      isDefault,
      houseCode,
      intercom,
      extraInformation
    );
    addresseObjt.longitude = address.longitude;
    addresseObjt.postGISPoint = postGISPoint;
    addresseObjt.lastModificationDatetime = lastModificationDatetime;
    addresseObjt.creationDatetime = creationDatetime;
    addresseObjt.isDeleted = isDeleted;
    setAddressObject(addresseObjt);
  }, [address, optionalInformation, isDefaults]);
  
  const createAddress = async () => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id && addresseObject) {
          await AddressRepository.createAddressForClient(
            addresseObject,
            clientWithId.id
          );
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const [loads, setLoads] = useState(false)
  useEffect(() => {
    (async () => {
      setLoads(true);
      try {
        setLoads(true)
        if (user) {
          const clientWithId = await ClientRepository.getCurrentClient();
          if (clientWithId.id) {
            const res = await AddressRepository.getClientAddresses(
              clientWithId.id
            );
            setOptions(res);
            setLoads(false)
          }
          setLoads(false);
        }
      } catch (error) {}
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      const addresse = await StepperStore.getItem("addresse");
      if (addresse !== null) {
        setDataAddresse(addresse.addresseObject);
      }
    })();
  }, [load]);
  const validate = () => {
    let erraddress = true;
    if (!validateAddresseAdd(address).isValid) {
      setError({
        addresse: validateAddresseAdd(address).message,
      });
      erraddress = true;
    } else {
      setError({ addresse: validateAddresseAdd(address).message });
      erraddress = false;
      handleClose();
    }
    if (erraddress) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    if (validate()) {
      if (user) {
        await createAddress();
        props.reLoad();
      } else {
        await StepperStore.setItem(
          "addresse",
          {
            addresseObject,
          },
          function () {}
        );
        setLoad(!load);
        props.reLoad();
      }
    } else {
    }
  };

  return (
    <>
      <div
        className={
          !user
            ? "font-nunito overflow-x-hidden w-4/5 pt-2  mx-auto md:translate-y-14 "
            : user && options.length === 0
            ? "font-nunito overflow-x-hidden w-4/5 pt-2  mx-auto md:translate-y-14 "
            : "font-nunito overflow-x-hidden"
        }
      >
        {/* <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-[15px]"> */}
        <BootstrapButton
          className="w-full mx-auto "
          variant="contained"
          onClick={handleClickOpen}
        >
          {/* <AddHomeIcon /> */}
          {!user ? (
            <input
              className="text-sm md:text-lg lg:2xl w-full "
              value={
                dataAddresse
                  ? dataAddresse?.streetNumber +
                    " " +
                    dataAddresse?.streetName +
                    ", " +
                    dataAddresse?.zip +
                    " " +
                    dataAddresse?.city
                  : "Ajouter une adresse"
              }
              type="button"
            />
          ) : user && options.length === 0 ? (
           loads ?
             <CircularProgress
            sx={{
              color: "gray",
            }}
            size={20}
            thickness={4}
            value={100}
          /> :
             <input
              className="text-sm md:text-lg lg:2xl w-full "
              value={"Ajouter une adresse"}
              type="button"
            />
          ) : (
            <input
              className="text-sm md:text-lg lg:2xl w-full "
              value={"Ajouter une adresse"}
              type="button"
            />
          )}
        </BootstrapButton>
        {/* </div> */}

        <Dialog open={opens} onClose={handleClose}>
          <div className="">
            <DialogTitle>
              <div className="flex justify-between">
                <span className="text-dark_blue md:text-xl text-base font-bold font-nunito">
                  Ajouter une adresse de facturation
                </span>
                <img
                  src={close}
                  alt=""
                  className="w-3 h-3 translate-y-2"
                  onClick={handleClose}
                />
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-lg py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-sm font-medium"
                >
                  Adresse de facturation
                </label>
                <div className=" -ml-4 -mt-2">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyANSjx8ZFHJequpxZ4vyxnXLyTqv90mI2U"
                    placeholder="Enter Location"
                    backspaceRemovesValue
                    apiOptions={{ language: "fr", region: "fr" }}
                    autocompletionRequest={{}}
                    components={{
                      LoadingMessage: () => "test",
                    }}
                    selectProps={{
                      isClearable: true,
                      placeholder: "Ex : 22 rue auber, Paris",
                      className: "border-none w-full bg-transparent",
                      // value: address.formatted_address,
                      onChange: (val) => {
                        //setAddress(val);
                        getElementAddress(val.value.place_id);
                      },

                      styles: {
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          border: "none",
                          display:'none',
                          color: "transparent",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          border: "none",
                          color: "#959595",
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          border: "none",
                          color: "white",
                          display:'none',
                        }),
                        noOptionsMessage: (provided) => ({
                          ...provided,
                          border: "none",
                          color: "white",
                        }),
                        loadingMessage: (provided) => ({
                          ...provided,
                          border: "none",
                          color: "white",
                        }),
                        menu: (provided) => ({
                          ...provided,

                          fontFamily: "Nunito",
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          border: 0,
                          // This line disable the blue border
                          //fontSize:"16px",
                          boxShadow: "none",
                          paddingLeft: ".5rem",
                          backgroundColor: "none",
                          fontFamily: "Nunito",
                        }),
                      },
                    }}
                  />
                </div>
              </div>
              <div className="mt-1 flex space-x-2 md:text-sm text-xs w-4/5">
                {error.addresse === "null" || error.addresse === ""
                  ? " "
                  : validateAddresseAdd(address).icon}
                <p className="text-[#FF724F] h-6 w-full ">
                  {error.addresse === "null" || error.addresse === ""
                    ? null
                    : validateAddresseAdd(address).message}
                </p>
              </div>
              <div>
                <p className="text-dark_blue font-nunito font-bold text-lg">
                  Autres informations (Facultatif){" "}
                </p>
                <div className="space-y-4 ">
                  <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                    <label
                      htmlFor=""
                      className="lg:text-lg md:text-base text-sm font-medium"
                    >
                      Code d'accès
                    </label>
                    <input
                      type="text"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : 123A"
                      onChange={(e) => {
                        setOptionalInformation({
                          houseCode: e.target.value,
                          intercom: optionalInformation.intercom,
                          extraInformation:
                            optionalInformation.extraInformation,
                        });
                      }}
                    />
                  </div>

                  <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                    <label
                      htmlFor=""
                      className="lg:text-lg md:text-base text-sm font-medium"
                    >
                      Interphone
                    </label>
                    <input
                      type="text"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : Dupond"
                      onChange={(e) => {
                        setOptionalInformation({
                          houseCode: optionalInformation.houseCode,
                          intercom: e.target.value,
                          extraInformation:
                            optionalInformation.extraInformation,
                        });
                      }}
                    />
                  </div>

                  <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                    <label
                      htmlFor=""
                      className="lg:text-lg md:text-base text-sm font-medium"
                    >
                      Autres précisions
                    </label>
                    <input
                      type="text"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : 1er étage à gauche"
                      onChange={(e) => {
                        setOptionalInformation({
                          houseCode: optionalInformation.houseCode,
                          intercom: optionalInformation.intercom,
                          extraInformation: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="translate-y-2 md:mx-auto">
                  <FormControl>
                    <RadioGroup row defaultValue="0" name="customized-radios">
                      <div className="">
                        <FormControlLabel
                          onChange={() => setIsDefault(true)}
                          sx={{ color: "black" }}
                          value="0"
                          control={
                            <RadioButton border="1px solid #fff" bg="#219CFF" />
                          }
                          label={
                            <Typography
                              style={{ fontFamily: "Nunito" }}
                              className="md:text-base lg:text-lg text-sm"
                            >
                              Adresse postale
                            </Typography>
                          }
                        />
                      </div>
                      <div className=" mg:ml-10">
                        <FormControlLabel
                          onChange={() => setIsDefault(false)}
                          sx={{ color: "black" }}
                          value="1"
                          control={
                            <RadioButton border="1px solid #fff" bg="#219CFF" />
                          }
                          label={
                            <Typography
                              style={{ fontFamily: "Nunito" }}
                              className="md:text-base lg:text-lg text-sm"
                            >
                              Adresse secondaire
                            </Typography>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="w-1/2 mb-4 rounded-2xl shadow-md shadow-[rgba(0,0,0,25%)] mx-auto ">
                <BootstrapButton
                  onClick={() => handleSubmit()}
                  className="w-full border-2 mx-auto text-sm md:text-lg  "
                  variant="contained"
                  type="submit"
                >
                  Valider
                </BootstrapButton>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    </>
  );
}
