import moment from 'moment';
import Functions from '../../../../../../../Helpers/Functions';
import * as React from 'react';
import { capitalizeFirstLetter, showStatus } from '../../../UtilsDashboard/FunctionsDashbord';
import PunctualCourseRepository from '../../../../../../../Repositories/PunctualCourseRepository';
import CustomFeesRepository from '../../../../../../../Repositories/CustomFeesRepository';
import ChildRepository from '../../../../../../../Repositories/ChildRepository';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import {status} from '../../../../../../../Enum/Enum'
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const ComponentBodyTablePunctualCoursePast = (props: any) => {
  const { punctual, isOpen, checked } = props;
  const formattedTime = moment(punctual.startDatetime).format('HH:mm')
  const modifiedTime = moment(formattedTime, 'HH:mm');
  const [downloadLinkInvoice, setDownloadLinkInvoice] = React.useState();
  const [downloadLinkInvoiceRecap, setDownloadLinkInvoiceRecap] = React.useState();
  const [customFees, setCustomFees] = React.useState<any>();
  const priceCustomFees = !customFees?.is_teachr_contact ? (((punctual?.teachrPrice) * punctual?.duration) / 2).toFixed(2) : (((punctual?.teachrPrice + punctual?.fees) * punctual?.duration) / 2).toFixed(2)

  React.useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachrs: any) => {
      ChildRepository.fetchChildById(punctual?.forChild_id).then((res: any) => {
        const idClient = parseInt(res?.parent["@id"].substr(9));
        if (idClient) {
          CustomFeesRepository.getCustomFeesSingle(idClient, teachrs.id).then(
            (customFees) => {
              if (customFees) {
                setCustomFees(customFees[0]);
                PunctualCourseRepository.downloadInvoice(
                  punctual?.charge_id
                ).then((response) => {
                  // if (customFees[0].is_teachr_contact) {
                    setDownloadLinkInvoice(response?.invoice);
                  // } else {
                    setDownloadLinkInvoiceRecap(response?.invoiceRecap);
                  // }
                });
              }
            }
          );
        }
      });
    });
  }, [punctual?.forChild_id]);
  
  const showDownloadButton = (statutUrssaf:string) => {
    if (customFees) {
      switch (statutUrssaf) {
        case status.STATUS_PAID.status:
          return (
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <span className="absolute inset-0 flex opacity-50 cursor-pointer" />
              <KeyboardDoubleArrowRightIcon sx={{ width: 17 }} />
              <a
                target="_blank"
                download
                href={
                  !customFees?.is_teachr_contact
                    ? downloadLinkInvoice
                    : downloadLinkInvoice
                }
                className="relative text-xs text-[#4d4d4d] border-b-[#4d4d4d] border-b-2"
              >
                Télécharger
                <ArrowCircleDownIcon sx={{ width: 17 }} />
              </a>
            </span>
          );
        case status.STATUS_VALIDATED.status:
          return (
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <span className="absolute inset-0 flex opacity-50 cursor-pointer" />
              <KeyboardDoubleArrowRightIcon sx={{ width: 17 }} />
              <a
                target="_blank"
                download
                href={
                  !customFees?.is_teachr_contact
                    ? downloadLinkInvoice
                    : downloadLinkInvoice
                }
                className="relative text-xs text-[#4d4d4d] border-b-[#4d4d4d] border-b-2"
              >
                Télécharger
                <ArrowCircleDownIcon sx={{ width: 17 }} />
              </a>
            </span>
          );
        case status.STATUS_REFUSAL_OF_DIRECT_DEBIT.status:
          return (
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <span className="absolute inset-0 flex opacity-50 cursor-pointer" />
              <KeyboardDoubleArrowRightIcon sx={{ width: 17 }} />
              <a
                target="_blank"
                download
                href={
                  !customFees?.is_teachr_contact
                    ? downloadLinkInvoice
                    : downloadLinkInvoice
                }
                className="relative text-xs text-[#4d4d4d] border-b-[#4d4d4d] border-b-2"
              >
                Télécharger
              </a>
              <ArrowCircleDownIcon sx={{ width: 17 }} />
            </span>
          );
        case status.STATUS_COVERED.status:
          return (
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <KeyboardDoubleArrowRightIcon sx={{ width: 17 }} />
              <span className="absolute inset-0 flex opacity-50 cursor-pointer" />
              <a
                target="_blank"
                download
                href={
                  !customFees?.is_teachr_contact
                    ? downloadLinkInvoice
                    : downloadLinkInvoice
                }
                className="relative text-xs text-[#4d4d4d] border-b-[#4d4d4d] border-b-2 "
              >
                Télécharger
                <ArrowCircleDownIcon sx={{ width: 17 }} />
              </a>
            </span>
          );

        default:
          return (
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <KeyboardDoubleArrowRightIcon sx={{ width: 17 }} />
              <span className="absolute inset-0 flex opacity-50 cursor-not-allowed" />
              Non disponible
            </span>
          );
      }
    }
  }
  return (
    <>
      <tr className="group/item hover:bg-slate-100 hover:rounded-xl hover:py-4">
        <td className="xl:px-2 px-2 md:py-2  whitespace-no-wrap  text-first border-gray-500 text-sm leading-5 md:flex hidden ">
          {/* {punctual?.chapter === "punctual_month" */}
          {punctual?.isPunctualMonth
            ? "Du " +
              moment(punctual.startDatetime).format("DD MMM YYYY") +
              " au " +
              moment(punctual.endDatetime).format("DD MMM YYYY")
            : "Le " +
              moment(punctual.startDatetime).format("DD MMM YYYY") +
              " à " +
              modifiedTime.format("HH:mm")}
        </td>
        <td className="xl:px-2 px-2 py-2 whitespace-no-wrap  border-gray-500 md:hidden">
          <div className="text-sm leading-5 text-first">
            {/* {punctual?.chapter === "punctual_month" */}
            {punctual?.isPunctualMonth
              ? moment(punctual.startDatetime).format("DD/MM/YYYY") +
                " au " +
                moment(punctual.endDatetime).format("DD/MM/YYYY")
              : "Le " +
                moment(punctual.startDatetime).format("DD/MM/YYYY") +
                " à " +
                modifiedTime.format("HH:mm")}
          </div>
        </td>
        <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap text-left border-gray-500 text-first text-sm leading-5">
          {Functions.renderDuration(punctual.duration)}
        </td>
        <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5">
          {capitalizeFirstLetter(punctual?.forChildFirstName) +
            " " +
            capitalizeFirstLetter(punctual?.forChildLastName)}
        </td>
        <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
          {punctual?.subjectAsked}
        </td>
        {isOpen ? (
          ""
        ) : (
          <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
            {/* {punctual?.chapter === "punctual_month" */}
            {punctual?.isPunctualMonth ? "Cours du mois" : "Cours ponctuel"}
          </td>
        )}
        <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap  border-gray-500 text-first text-center text-sm leading-5">
          {checked
            ? priceCustomFees
            : ((punctual?.teachrPrice * punctual?.duration) / 2).toFixed(2)}
          €
        </td>
        <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap text-center text-first border-gray-500 text-sm leading-5">
          {showStatus(punctual)}
        </td>
        {isOpen ? (
          ""
        ) : (
          <td className="xl:px-2 px-2 md:py-2 whitespace-no-wrap text-first border-gray-500 text-sm leading-5">
            {showDownloadButton(punctual?.urssafServiceStatus)}
          </td>
        )}
      </tr>
      {/* } */}
    </>
  );
};

export default ComponentBodyTablePunctualCoursePast;