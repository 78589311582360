import { Button, CircularProgress } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Functions from "../../../Helpers/Functions";
import StepperStore from "../../../LocalForage/StepperStore";
import { Address } from "../../../Objects/models/Address";
import Child from "../../../Objects/models/Child";
import Client from "../../../Objects/models/Client";
import Teachr from "../../../Objects/models/Teachr";
import TeachrContact from "../../../Objects/models/TeachrContact";
import AddressRepository from "../../../Repositories/AddressRepository";
import ChildRepository from "../../../Repositories/ChildRepository";
import ClientRepository from "../../../Repositories/ClientRepository";
import TeachrContactRepository from "../../../Repositories/TeachrContactRepository";
import URSSAFParentRepository from "../../../Repositories/URSSAFParentRepository";
import ApiService from "../../../Services/ApiService";
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  FirebaseAuthServiceErrorCode,
} from "../../../Services/FirebaseService";
import Layout from "./Layout";
import CustomFees from "../../../Objects/models/CustomFees";
import CustomFeesRepository from "../../../Repositories/CustomFeesRepository";

const Fourth = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [userComplete, setUserComplete] = useState(false);
  const [clientComplete, setClientComplete] = useState(false);
  const [addressComplete, setAddressComplete] = useState(false);
  const [childComplete, setChildComplete] = useState(false);
  const [favoryTeachrComplete, setFavoryTeachrComplete] = useState(false);
  const [aiciComplete, setAiciComplete] = useState(false);
  const [teachrContact, setTeachrContact] = useState<TeachrContact>();
  const [teachr, setTeachr] = useState<Teachr>();
  const [address, setAddress] = useState<Address>();
  const [user] = useAuthState(auth);
  const [token, setToken] = useState("");
  const [client, setClient] = useState<Client>();
  const [loading, setLoading] = useState(false);
  const [childClient, setChildClient] = useState<Child>();

  useEffect(() => {
    if (user) {
      user.getIdToken().then((response) => {
        setToken(response);
      });
      StepperStore.getItem(user ? `teachrContact${user?.uid}` : "teachrContact").then((item: any) => {
        setChildClient(item);
      });
    }
  }, [user]);

  useEffect(() => {
    if (params.key) {
      TeachrContactRepository.fetchTeachrContactByKey(params.key)
        .then((response) => {
          if (response) {
            setTeachrContact(response);
            setTeachr(response.teachr);
            if (response.address) {
              setAddress(response.address);
            }
            StepperStore.getItem("addresseFacturation").then((item: any) => {
              if (item) {
                setAddress(item);
              }
            });
          }
        })
        .catch(() => navigate("/notfound"));
    } else {
      navigate("/notfound");
    }
  }, []);

  useEffect(() => {
    if (loading && userComplete) {
      if (token && address && teachrContact) {
        const client = new Client(
          token,
          teachrContact.firstName!,
          teachrContact.name!,
          Functions.formatPhoneNumber(teachrContact.phoneNumber!),
          true,
          "firebaseMessagingToken", // TODO registrationToken
          true, // TODO parent/child
          false,
          // teachrContact.level!
          0
        );
        client.isRegisteredByTeachr = true;
        client.mail = teachrContact.mail!;
        client.sex = teachrContact.gender === "1" ? "MAN" : "WOMAN";
        client.isNotify = teachrContact.isNotify;
        ClientRepository.createClient(client)
          .then((response1) => {
            ApiService.disableAuth();
            ApiService.enableAuth(undefined, undefined, "client " + token);
            setClient(response1);
            setClientComplete(true);
            setUserComplete(false);
          })
          .catch((error) => {

            ApiService.disableAuth();
            ApiService.enableAuth(undefined, undefined, "client " + token);
            ClientRepository.getCurrentClient()
              .then((response2) => {
                if (response2.firstName !== teachrContact.firstName) {
                  response2.firstName = teachrContact.firstName!;
                }
                if (response2.lastName !== teachrContact.name) {
                  response2.lastName = teachrContact.name!;
                }
                const sexe = teachrContact.gender === "1" ? "MAN" : "WOMAN";
                if (response2.sex !== sexe) {
                  response2.sex = sexe!
                }

                ClientRepository.updateClient(response2)
                  .then((newClient) => {
                    setClient(newClient);
                    setClientComplete(true);
                    setUserComplete(false);
                  })
                  .catch((error) => {
                    setClientComplete(false);
                    setLoading(false);
                  });
              })
              .catch((error) => {
                console.warn(error);

                setClientComplete(false);
                setLoading(false);
              });
          });
      }
    }
  }, [address, loading, teachrContact, token, user, userComplete]);
  useEffect(() => {
    if (loading && clientComplete) {
      if (address && client) {
        let addressObj = address;
        // if (oldAddress) {
        //     if (address.formattedAddress !== oldAddress.formattedAddress) {
        //         addressObj = oldAddress
        //     }
        // }
        addressObj.sessionToken = "";
        addressObj.isDefault = true;
        addressObj.owner = client["@id"];
        if (client.addresses.length === 0) {
          AddressRepository.createAddress(addressObj)
            .then((response) => {
              if (response && teachrContact) {
                teachrContact.address = response["@id"];
                TeachrContactRepository.updateTeachrContact(teachrContact)
                  .then(() => {
                    setAddress(response);
                    setAddressComplete(true);
                    setClientComplete(false);
                  })
                  .catch((error) => {
                    setAddressComplete(false);
                    setLoading(false);
                  });
              }
            })
            .catch((error) => {
              setAddressComplete(false);
              setLoading(false);
            });
        } else {
          setAddressComplete(true);
          setClientComplete(false);
        }
      }
    }
  }, [loading, clientComplete, address, client]);

  useEffect(() => {
    if (loading && addressComplete) {
      if (client && teachr) {
        ClientRepository.addFavoriteTeachr(client, teachr)
          .then((response) => {
            if (client["@id"] && teachr["@id"]) {
              const customFees = new CustomFees(
                [client["@id"]],
                [teachr["@id"]],
                null,
                null,
                client.isRelationTeachr ? false : true
              );
              if (favoryTeachrComplete === false) {
                if (client.id && teachr.id) {
                  CustomFeesRepository.getCustomFeesSingle(client?.id, teachr.id).then((feesCust) => {
                    if (feesCust.length === 0) {
                      CustomFeesRepository.createCustomFees(customFees).then((res) => {
                        setFavoryTeachrComplete(true);
                        if (res?.isTeachrContact === false) {
                          if (client.id && teachr.id && res?.id) {
                            CustomFeesRepository.sendMailToTeachr(client.id, teachr.id, res?.id)
                          }
                        }
                      }).catch((err) => {
                          console.warn(err);
                          setFavoryTeachrComplete(false);
                          setLoading(false);
                        });
                    } else {
                        feesCust.forEach((element:any) => {
                          setFavoryTeachrComplete(true);
                          if (element?.isTeachrContact === false) {
                            if (client.id && teachr.id && element?.id) {
                              CustomFeesRepository.sendMailToTeachr(client.id, teachr.id, element?.id)                          
                            }
                          }
                        })
                    }
                  })
                }
              }
            }
          })
      }
    }
  }, [loading, addressComplete, client, teachr]);
  useEffect(() => {
    if (loading && favoryTeachrComplete) {
      if (client && teachrContact) {
        const child = new Child(
          teachrContact.studentName!,
          client.lastName!,
          client["@id"]!,
          // teachrContact.level!, 
          0,
          new Date(teachrContact.birthDay!),
          Functions.formatPhoneNumber(teachrContact.phoneNumber!),
          ""
        );
        ChildRepository.getChildren(client.id!).then((response) => {
          if (response && response.length > 0) {
            const childrens = response.filter((children) => {
              return (
                children.firstName === child.firstName &&
                child.class === children.class
              );
            });
            if (childrens && childrens.length > 0) {
              setChildComplete(true);
            } else if (childClient !== undefined) {
              setChildComplete(true);
            } else {
              ChildRepository.createChild(child)
                .then((response) => {
                  setChildComplete(true);
                })
                .catch((error) => {
                  setChildComplete(false);
                  setLoading(false);
                });
            }
          } else {
            ChildRepository.createChild(child)
              .then((response) => {
                setChildComplete(true);
              })
              .catch((error) => {
                setChildComplete(false);
                setLoading(false);
              });
          }
        });
      }
    }
  }, [loading, favoryTeachrComplete, client, teachrContact]);
  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };
  useEffect(() => {
    if (loading && childComplete) {
      if (address && teachrContact) {
        const dataForTpres = {
          gender: teachrContact.gender,
          birth_name:
            teachrContact.gender === "2"
              ? teachrContact.birthName
              : teachrContact.name,
          birthDate: moment(teachrContact.birthDay).format("yyyy-MM-DD"),
          country_birth_code: teachrContact.isBornFrance
            ? "99100"
            : `99${Functions.getCodePays(teachrContact.country!)}`,
          birth_zip_code: teachrContact.isBornFrance
            ? teachrContact.zipCode
            : undefined,
          birth_wordingCommune: teachrContact.isBornFrance
            ? teachrContact.city
            : undefined,
          way_type_code: Functions.renderStreetWayType(address.streetName),
          country_code: "99100",
          city: removeAccents(address.city).toUpperCase(),
          // streetway_number: address.streetNumber,
          // "streetway_letter": "B",
          streetway_name: address.streetName,
          // "named_place": "Le Beyssat",
          zip_code: address.zip,
          bic: teachrContact.bic,
          iban: teachrContact.iban,
        };
        URSSAFParentRepository.tpresInscription(dataForTpres)
          .then((response) => {
            setAiciComplete(true);

          })
          .catch((error: Error) => {
            dispatch({
              type: "SHOW_ERROR",
              payload: JSON.parse(error.message).translate,
            });
            setAiciComplete(false);
            setLoading(false);
          });
      }
    }
  }, [loading, childComplete, address, teachrContact]);
  useEffect(() => {
    if (aiciComplete && teachrContact && address && teachr) {
      teachrContact.teachr = teachr["@id"]!;
      teachrContact.address = address["@id"];
      teachrContact.step = 5;
      TeachrContactRepository.updateTeachrContact(teachrContact).then(() => {
          props.nextStep(5)
        }
      );
    }
  }, [address, aiciComplete, teachr, teachrContact]);

  const nextStep = () => {
    if (!loading) {
      if (user) {
        setLoading(true);
        setUserComplete(true);
      } else {
        setLoading(true);
        if (teachrContact && teachr) {
          registerWithEmailAndPassword(teachrContact.mail, teachrContact.key!)
            .then((response) => {
              if (response) {
                setUserComplete(true);
              } else {
                setUserComplete(false);
              }
            })
            .catch((error) => {
              switch (error.code) {
                case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
                  logInWithEmailAndPassword(
                    teachrContact.mail,
                    teachrContact.key!
                  )
                    .then((response) => {
                      if (response) {
                        setUserComplete(true);
                      } else {
                        setUserComplete(false);
                      }
                    })
                    .catch(() => setUserComplete(false));
                  break;
                default:
                  break;
              }
            });
        }
      }
    }
  };
  const prevStep = () => {
    props.nextStep(2);
  };
  const renderScreen = () => {
    if (teachrContact && teachr && address) {
      dispatch({
        type: "HIDE_LOADER",
      });
      return (
        <Layout
          teachr={teachr.firstName}
          prev={
            <Button
              onClick={() => (!loading ? prevStep() : null)}
              className="w-full py-2 font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first md:text-base px-14 md:w-max border-first"
            >
              precedent
            </Button>
          }
          next={
            <Button
              onClick={() => nextStep()}
              className={`${
                loading && "bg-opacity-40 cursor-default"
              } w-full font-extrabold text-white uppercase rounded-full  md:text-base px-14 bg-first md:w-max py-2 border-first border-2 border-solid`}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "#fff",
                  }}
                />
              ) : (
                "VALIDER"
              )}
            </Button>
          }
        >
          <div>
            <ul className="flex flex-col gap-2">
              <li className="text-sm font-bold text-grey">
                Prénom de l’élève :{/* et niveau scolaire :{" "} */}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(0)}
                >{`${teachrContact.studentName} `}</span>
                {/* , $
                {teachrContact.level !== undefined
                  ? Functions.renderClass(teachrContact.level)
                  : ""} */}
              </li>
              <li className="text-sm font-bold text-grey">
                Prénom :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(0)}
                >
                  {teachrContact.firstName}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                Nom :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(0)}
                >
                  {teachrContact.name}
                </span>
              </li>
              {teachrContact.gender === "2" && (
                <li className="text-sm font-bold text-grey">
                  Nom de naissance :{" "}
                  <span
                    className="pl-2 font-semibold cursor-pointer text-first"
                    onClick={() => props.nextStep(1)}
                  >
                    {teachrContact.birthName}
                  </span>
                </li>
              )}
              <li className="text-sm font-bold text-grey">
                E-mail :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(0)}
                >
                  {" "}
                  {teachrContact.mail}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                Téléphone :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(0)}
                >
                  {teachrContact.phoneNumber}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                Civilité :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(1)}
                >
                  {teachrContact.gender === "1" ? "un homme " : "une femme"}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                Date de naissance :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(1)}
                >
                  {moment(teachrContact.birthDay).format("L")}
                </span>
              </li>
              <li
                className={`${
                  teachrContact.isBornFrance && "hidden"
                } text-sm font-bold text-grey`}
              >
                Pays de naissance :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(1)}
                >
                  {teachrContact.country}
                </span>
              </li>
              <li
                className={`${
                  !teachrContact.isBornFrance && "hidden"
                } text-sm font-bold text-grey`}
              >
                Code postal de naissance :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(2)}
                >
                  {teachrContact.zipCode!}
                </span>
              </li>
              <li
                className={`${
                  !teachrContact.isBornFrance && "hidden"
                } text-sm font-bold text-grey`}
              >
                Ville de naissance :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(2)}
                >
                  {teachrContact.city!}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                Adresse :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(1)}
                >
                  {address.formattedAddress}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                IBAN :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(2)}
                >
                  {teachrContact.iban &&
                    Functions.addEspacesIBAN(teachrContact.iban)}
                </span>
              </li>
              <li className="text-sm font-bold text-grey">
                BIC :{" "}
                <span
                  className="pl-2 font-semibold cursor-pointer text-first"
                  onClick={() => props.nextStep(2)}
                >
                  {teachrContact.bic}
                </span>
              </li>
            </ul>
          </div>
        </Layout>
      );
    }
    dispatch({
      type: "SHOW_DASHBOARD_AICI_LOADER",
    });
    return;
  };

  return <div className="w-full">{renderScreen()}</div>;
};

export default Fourth;
