import { useEffect, useState } from "react";
import StepperStore from "../../../../../../../LocalForage/StepperStore";
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import UpdateChoicePunctual from "./EditComponentPunctual/EditChoicePunctual";
import Skeleton from '@mui/material/Skeleton';
import * as React from 'react';

const EditPunctualChoice = (props: any) => {
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const [value, setValue] = useState<any>();
    const [load, setLoad] = useState(false);
    const { backStep } = props


    useEffect(() => {
        setLoad(true)
        StepperStore.getItem("course_id").then((value) => {
            // setLoad(true)
            if (value !== null) {
                setValue(value);
                setLoad(false)
            }
        })
    }, [load])

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
                    (course) => {
                        setCourse(course);
                    }
                );
            }
        });
    }, []);
    return (
      <>
        {course === undefined ? (
          <div className="h-[85%] mt-4 bg-white lg:shadow-simulator-dashboard rounded-lg list-teacher /lg:rounded-[30px] border-[2px] border-first">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        ) : (
          <div className="shadow-dashboard-setup-intro-form lg:mt-4 overflow-y-auto list-teacher lg:px-20 py-2  h-[85%] px-4 lg:py-4 bg-white lg:shadow-simulator-dashboard rounded-lg list-teacher /lg:rounded-[30px] border-[2px] border-first">
            <div className="mb-6">
              <h1 className="xl:text-xl lg:text-lg text-lg font-extrabold text-dark_blue">
                Modification du cours ponctuel
              </h1>
            </div>
            {course?.map((option: PunctualCourseDashboard, key) => (
              <div>
                {parseInt(value) === option.id && (
                  <div>
                    <UpdateChoicePunctual
                      backStep={backStep}
                      option={option}
                      key={option.id}
                      id={option.id}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    );
};

export default EditPunctualChoice;
