import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Address } from "../../../../../Objects/models/Address";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Teachr from "../../../../../Objects/models/Teachr";
import validateZipCode from "../../../../../Validation/zipCode";
import ValidateNni from "../../../../../Validation/Nni";
import ValidateIban from "../../../../../Validation/Iban";
import AddressRepository from "../../../../../Repositories/AddressRepository";
import TeachrRepository from "../../../../../Repositories/TeachrRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import closeCircle from "../../../../../Assets/Icons/close_circle.svg";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import Input from "../../../../../Components/utils/Input";
import AddressComponent from "../../../../../Components/utils/Address";
import RadioButton from "../../../../../Components/utils/RadioButton";
import Functions from "../../../../../Helpers/Functions";
import { status, statusTpdEnum } from "../../../../../Enum/Enum";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";
import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import CustomFeesRepository from "../../../../../Repositories/CustomFeesRepository";
import TeachrContactRepository from "../../../../../Repositories/TeachrContactRepository";
import Backdrop from "@mui/material/Backdrop";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import ArchiveTwoToneIcon from "@mui/icons-material/ArchiveTwoTone";
import RefreshIcon from "@mui/icons-material/Refresh";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import UnarchiveTwoToneIcon from "@mui/icons-material/UnarchiveTwoTone";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const MySwal = withReactContent(Swal);
let timerInterval: any;
interface statusInterface {
  valid: boolean;
  status: boolean;
}
interface StatusCourse {
  urssafServiceStatus: string;
  isOver: boolean;
}
interface dataProfileModel {
  handleOpenView: () => void;
  handleClose: () => void;
  fullName: string;
  initFullName: string;
  child: any;
  isLoad2: boolean;
  firstCondition: boolean;
  isDelete2: boolean;
  openView: boolean;
  address: any;
  isTrue: boolean;
  handleRefresh: () => void;
}
interface dataDeleteModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  setIsLoad2: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface dataArchiveModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface btnArchiveModel {
  data: dataArchiveModel;
  load: boolean;
  firstCondition: boolean;
}
interface btnDeleteModel {
  data: dataDeleteModel;
  load: boolean;
  firstCondition: boolean;
}
interface dataStatusModel {
  child: any;
  firstCondition: boolean;
  handleRefresh: () => void;
  isLoad2: boolean;
}
interface dataDeclareModel {
  child: any;
  firstCondition: boolean;
  nextDeclareCourse: () => void;
  fullName: string;
}
// const dataDeclare = {
//   firstCondition,
//   child,
//   nextDeclareCourse,
// };
export const handleDeclare = (data: dataDeclareModel) => {

  if (data?.child?.etat_tdp === "OK" && !data?.firstCondition) {
    return (
      <Tooltip
        title={`Vous pouvez déclarer un cours pour ${data?.fullName} en cliquant ici.`}
        className="font-nunito cursor-auto"
      >
        <>
          <div className="md:flex hidden md:gap-x-1 items-center">
            <button
              className={`border-2 px-2 font-semibold border-first text-first rounded-full group-hover/edit:text-gray-700 group-hover/edit:border-gray-700 group-hover/edit:font-bold `}
              onClick={() => {
                data?.nextDeclareCourse();
              }}
            >
              Déclarer
            </button>
            <DoubleArrowIcon className="text-first group-hover/edit:translate-x-0.5 group-hover/edit:text-slate-500 group-hover/edit:font-bold md:block hidden" />
          </div>
          <p
            onClick={() => {
              data?.nextDeclareCourse();
            }}
            className="md:hidden "
          >
            Déclarer
          </p>
        </>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={``} className="font-nunito cursor-auto">
        <>
          <div className="md:flex hidden gap-x-1 items-center">
            <div
              className={`border-2 px-2 font-semibold cursor-not-allowed ${data?.firstCondition
                  ? "text-[#ff9647] border-[#ff9647]"
                  : "text-[#4D4D4D] border-[#4D4D4D]"
                } rounded-full :group-hover/edit:text-gray-700 :group-hover/edit:border-gray-700 :group-hover/edit:font-bold `}
            >
              Déclarer
            </div>
            <DoubleArrowIcon
              className={`${data?.firstCondition
                  ? "text-[#ff9647] border-[#ff9647]"
                  : "text-[#4D4D4D] border-[#4D4D4D]"
                }  cursor-not-allowed group-hover/edit:translate-x-0.5 :group-hover/edit:text-slate-500 :group-hover/edit:font-bold md:block hidden`}
            />
          </div>
          <p className="md:hidden">Déclarer</p>
        </>
      </Tooltip>
    );
  }
};
export const stepper = (status: statusInterface) => {
  return (
    <div className="flex w-[100%] pt-2">
      <div className="border-2 border-first bg-[#c7e4ff] relative text-dark_blue font-bold rounded-2xl w-6 h-6 text-center pt-1">
        <p className="text-xs text-dark_blue font-bold -translate-y-6 -translate-x-1 ">
          Envoyé
        </p>
        <p className="-translate-y-6 absolute w-3 text-white">
          <CheckOutlinedIcon className="text-first" />
        </p>
      </div>
      <div className={"border-b-4 border-dark_blue w-[25%] -translate-y-3"} />
      <div
        className={`${!status?.status ? "border-grey" : "bg-[#c7e4ff] border-first"
          } border-2 text-dark_blue font-bold rounded-2xl w-6 h-6 text-center pt-1`}
      >
        <p
          className={`${status?.status ? "text-first" : "text-grey"
            } text-xs font-bold -translate-y-6 w-14 -translate-x-4`}
        >
          {status?.status ? "Complété" : "En cours"}
        </p>
        <p
          className={`${status.status
              ? "text-first -translate-y-6"
              : "text-grey -translate-y-5"
            }`}
        >
          {status?.status ? <CheckOutlinedIcon /> : 2}
        </p>
      </div>
      <div
        className={`${status?.status ? "border-dark_blue" : "border-grey"
          } border-b-4  w-[25%] -translate-y-3`}
      />
      <div
        className={`${status.valid ? "bg-[#c7e4ff] border-first" : "border-grey"
          } border-2 text-dark_blue font-bold rounded-2xl w-6 h-6 text-center pt-1`}
      >
        <p
          className={`${status.valid ? "text-first" : "text-grey"
            } text-xs font-bold -translate-y-6 -translate-x-1`}
        >
          Validé
        </p>
        <p
          className={`${status.valid
              ? "text-first -translate-y-6"
              : "text-grey -translate-y-5"
            }`}
        >
          {status.valid ? <CheckOutlinedIcon /> : 3}
        </p>
      </div>
    </div>
  );
};
export const showStatusTDP = (data: dataStatusModel) => {
  if (data?.child?.status_tdp) {
    if (data?.child?.etat_tdp === statusTpdEnum.VALIDE.etat) {
      return (
        <Tooltip
          title={statusTpdEnum.VALIDE.value}
          className="font-nunito cursor-auto "
        >
          <div className="/px-2 flex items-center">
            <CheckCircleTwoToneIcon
              sx={{ color: data?.firstCondition ? "#ff9647" : "green" }}
            />
            <p
              className={`${data?.firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
                } md:text-sm text-xs /md:block cursor-auto /hidden`}
            >
              {statusTpdEnum.VALIDE.response}
            </p>
          </div>
        </Tooltip>
      );
    } else {
      switch (data?.child?.code_tdp) {
        case statusTpdEnum.EN_COURS.status:
          return (
            <Tooltip
              title={statusTpdEnum.EN_COURS.value}
              className="font-nunito cursor-auto"
            >
              <div
                className=" /px-2  text-dark_blue cursor-pointer flex items-center"
                onClick={data?.handleRefresh}
              >
                {data?.isLoad2 && data?.child?.client ? (
                  <CircularProgress
                    sx={{ width: "25px !important", height: "25px !important" }}
                  />
                ) : (
                  <div className="flex items-center">
                    <RefreshIcon
                      sx={{
                        color:
                          data?.child?.code_tdp ===
                            statusTpdEnum.EN_COURS.status
                            ? "red"
                            : "",
                      }}
                      onClick={data?.handleRefresh}
                    />
                    <p
                      className={`${data?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                        } md:text-sm text-xs /md:block cursor-auto /hidden`}
                    >
                      {statusTpdEnum.EN_COURS.response}{" "}
                    </p>
                  </div>
                )}
              </div>
            </Tooltip>
          );
          break;
        case statusTpdEnum.EN_ATTENTE.status:
          return (
            <Tooltip
              title={statusTpdEnum.EN_ATTENTE.value}
              className="font-nunito cursor-auto"
            >
              <div
                className=" /px-2  text-dark_blue cursor-pointer flex items-center"
                onClick={data?.handleRefresh}
              >
                {data?.isLoad2 && data?.child?.client ? (
                  <CircularProgress
                    sx={{ width: "25px !important", height: "25px !important" }}
                  />
                ) : (
                  <div className="flex items-center">
                    <RefreshIcon
                      sx={{
                        color:
                          data?.child?.code_tdp ===
                            statusTpdEnum.EN_ATTENTE.status
                            ? "red"
                            : "",
                      }}
                      onClick={data?.handleRefresh}
                    />
                    <p
                      className={`${data?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                        } md:text-sm text-xs /md:block cursor-auto /hidden`}
                    >
                      {statusTpdEnum.EN_ATTENTE.response}{" "}
                    </p>
                  </div>
                )}
              </div>
            </Tooltip>
          );
          break;
        case statusTpdEnum.BLOQUE.status:
          return (
            <Tooltip
              title="Le compte du client est bloqué auprès de l'Urssaf"
              className="font-nunito cursor-auto"
            >
              <div
                className=" /px-2  text-dark_blue cursor-pointer flex items-center"
                onClick={data?.handleRefresh}
              >
                {data?.isLoad2 && data?.child?.client ? (
                  <CircularProgress
                    sx={{ width: "25px !important", height: "25px !important" }}
                  />
                ) : (
                  <div className="flex items-center">
                    <RefreshIcon
                      sx={{
                        color:
                          data?.child?.code_tdp === statusTpdEnum.BLOQUE.status
                            ? "red"
                            : "",
                      }}
                      onClick={data?.handleRefresh}
                    />
                    <p
                      className={`${data?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                        } md:text-sm text-xs /md:block cursor-auto /hidden`}
                    >
                      {statusTpdEnum.BLOQUE.response}{" "}
                    </p>
                  </div>
                )}
              </div>
            </Tooltip>
          );
          break;
        case statusTpdEnum.ERROR.etat:
          return (
            <Tooltip
              title={data.child?.description_tdp}
              className="font-nunito cursor-auto"
            >
              <div
                className="text-dark_blue cursor-pointer flex items-center"
                onClick={data?.handleRefresh}
              >
                {data?.isLoad2 && data?.child?.client ? (
                  <CircularProgress
                    sx={{ width: "25px !important", height: "25px !important" }}
                  />
                ) : (
                  <div className="flex items-center">
                    <RefreshIcon
                      sx={{
                        color: "red",
                      }}
                      onClick={data?.handleRefresh}
                    />
                    <p
                      className={`${data?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-red-700"
                        } md:text-sm text-xs cursor-auto`}
                    >
                      {statusTpdEnum.ERROR.response}{" "}
                    </p>
                  </div>
                )}
              </div>
            </Tooltip>
          );
          break;
        default:
          return (
            <Tooltip
              title="Le formulaire de l'élève a été complété. Il a reçu un email de l'URSSAF pour activer son compte Avance Immédiate en suivant les instructions, incluant la création d'un mot de passe et l'acceptation des conditions d'utilisation. Une fois cela fait, vous pourrez valider son statut. Si l'élève a déjà un compte sur l'URSSAF, il doit se connecter, aller dans la section 'Intervenants' et autoriser Teach'r comme nouvel intervenant."
              className="font-nunito cursor-auto"
            >
              <div
                className=" /px-2 text-dark_blue cursor-pointer flex items-center "
                onClick={data?.handleRefresh}
              >
                {data?.isLoad2 && data?.child?.client ? (
                  <CircularProgress
                    sx={{ width: "25px !important", height: "25px !important" }}
                  />
                ) : (
                  <div className="flex items-center">
                    <RefreshIcon onClick={data?.handleRefresh} />
                    <p
                      className={`${data?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                        } md:text-sm text-xs /md:block cursor-auto /hidden`}
                    >
                      En cours
                    </p>
                  </div>
                )}
              </div>
            </Tooltip>
          );
          break;
      }
    }
  } else {
    return (
      <Tooltip
        title="Le formulaire de l'élève a été complété. Il a reçu un email de l'URSSAF pour activer son compte Avance Immédiate en suivant les instructions, incluant la création d'un mot de passe et l'acceptation des conditions d'utilisation. Une fois cela fait, vous pourrez valider son statut. Si l'élève a déjà un compte sur l'URSSAF, il doit se connecter, aller dans la section 'Intervenants' et autoriser Teach'r comme nouvel intervenant."
        className="font-nunito"
      >
        <div
          className=" px-2  text-dark_blue cursor-pointer flex items-center"
          onClick={data?.handleRefresh}
        >
          {data?.isLoad2 && data?.child?.client ? (
            <CircularProgress
              sx={{ width: "25px !important", height: "25px !important" }}
            />
          ) : (
            <div className="flex items-center">
              <RefreshIcon onClick={data?.handleRefresh} />
              <p className="text-sm md:block hidden">En cours</p>
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
};
export const profileStudent = (datas: dataProfileModel) => {
  const dataStatus: dataStatusModel = {
    child: datas?.child,
    firstCondition: datas?.firstCondition,
    handleRefresh: datas?.handleRefresh,
    isLoad2: datas?.isLoad2,
  };
  return (
    <div className="flex gap-x-1">
      <Tooltip title="Consulter le profil de l'élève" className="font-nunito">
        <VisibilityTwoToneIcon
          sx={{ color: datas?.firstCondition ? "#ff9647" : "#0054A3" }}
          className={`${datas?.isTrue ? "block" : "hidden md:block"
            } cursor-pointer`}
          onClick={datas?.handleOpenView}
        />
      </Tooltip>
      <button
        className={`${datas?.isTrue ? "hidden" : "/md:hidden"} cursor-pointer`}
        onClick={datas?.handleOpenView}
      >
        Consulter
      </button>
      <Dialog
        open={!datas?.openView ? false : datas?.openView}
        onClose={datas?.handleOpenView}
      >
        <div className="text-left p-6 lg:w-[500px] font-nunito bg-[#F4FAFF]">
          <div
            className={`${datas?.firstCondition ? "border-[#ff9647]" : "border-[#0054A3]"
              } lg:text-lg md:text-base text-sm border-2 shadow-lg bg-white w-full rounded-lg h-[50%] p-4`}
          >
            <h1
              className={`lg:text-xl md:text-lg text-base underline font-extrabold ${datas?.firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
                } text-center my-4`}
            >
              Informations de l'élève
            </h1>
            <div
              className={`gap-x-4 mx-auto mt-2 border-b-2 shadow-lg rounded-xl pb-2 ${datas?.firstCondition ? "border-[#ff9647]" : "border-[#0054A3]"
                } md:flex md:items-start  justify-around`}
            >
              <div className="mx-auto md:pl-0 /pl-16 py-6 font-extrabold lg:text-2xl md:text-lg text-base">
                <Avatar
                  sx={{
                    bgcolor: datas?.firstCondition ? "#ffe5d1" : "#c7e4ff",
                    color: datas?.firstCondition ? "#ff9647" : "#0054A3",
                    width: "100px",
                    height: "100px",
                    fontFamily: "nunito",
                    border: datas?.firstCondition
                      ? "4px solid #ff9647"
                      : "4px solid #0054A3",
                  }}
                  className="mx-auto"
                >
                  {datas?.initFullName}
                </Avatar>
              </div>
              <div className="md:w-3/4 w-full mx-auto  space-y-1 lg:pl-4">
                <ul className=" py-4 space-y-1 md:text-sm text-xs mx-auto px-2">
                  <li className="text-[#4d4d4d] flex items-center gap-x-1 md:text-base lg:text-lg text-sm font-bold">
                    <AccountCircleTwoToneIcon
                      className={
                        datas?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                      }
                    />
                    {datas?.fullName}
                  </li>
                  <li className="text-[#4d4d4d] flex items-center gap-x-1">
                    <LocalPhoneTwoToneIcon
                      className={
                        datas?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                      }
                    />
                    {" " + datas?.child?.phone}
                  </li>
                  <li className="text-[#4d4d4d] flex items-center gap-x-1">
                    <MailTwoToneIcon
                      className={
                        datas?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                      }
                    />
                    {" " + datas?.child?.mail}
                  </li>
                  <li className="text-[#4d4d4d] flex items-center gap-x-1">
                    <HomeTwoToneIcon
                      className={
                        datas?.firstCondition
                          ? "text-[#ff9647]"
                          : "text-[#0054A3]"
                      }
                    />
                    {" " + datas?.child?.address}
                  </li>
                </ul>
                <div className="w-full mx-auto mt-1 px-2">
                  <div
                    className={`${datas?.firstCondition
                        ? "border-[#ff9647]"
                        : "border-[#0054A3]"
                      } border-2 rounded-full w-1/2 /mx-auto text-center justify-center items-center flex p-1`}
                  >
                    {showStatusTDP(dataStatus)}
                  </div>
                </div>
              </div>
            </div>
            <ul className=" py-4 space-y-2"></ul>
            {!datas?.child?.is_teachr_contact &&
              (!datas?.child?.price_per_hour ||
                !datas?.child?.fees_per_hour) && (
                <div className="font-nunito lg:text-lg md:text-base text-base p-2 border-t-2 px-4 text-gray-400 font-bold block">
                  <p className="text-sm font-regular">
                    Ce compte n'est actuellement pas actif. Veuillez patienter
                    jusqu'à ce qu'un administrateur le valide. Ce processus
                    prendra en moyenne deux jours
                  </p>
                </div>
              )}
            <div className="flex mx-auto pt-6 justify-between py-2">
              <button
                onClick={() => datas?.handleClose()}
                className={`block ${datas?.firstCondition
                    ? "text-[#ff9647] bg-[#ffe5d1] border-[#ff9647]"
                    : "text-[#0054A3] bg-[#c7e4ff] border-[#0054A3]"
                  } font-extrabold lg:text-lg md:text-base text-sm text-center rounded-full md:px-14 px-8 py-1   border-solid border-2 w-full`}
              >
                {datas?.isLoad2 && datas?.isDelete2 === false
                  ? "Chargement..."
                  : "Retour"}
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
const displayeDelete = (data: dataDeleteModel) => {
  return (
    <>
      {Swal.fire({
        title: "Êtes-vous sûr ?",
        text: 'En appuyant sur le bouton "Oui, supprimer!" , vous confirmez la suppression de l\'élève.',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#4d4d4d",
        confirmButtonText: "Oui, supprimer !",
        cancelButtonText: "Annuler",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          data?.setLoad(true);
          if (data?.child?.client_id) {
            data?.setLoad(true);
            Teachr_Repository.getCurrentTeachr().then((res) => {
              data?.setLoad(true);
              if (res?.id) {
                data?.setLoad(true);
                Teachr_Repository.removeTeachrFavoriteByTeachr(
                  data?.child?.client_id,
                  res?.id
                )
                  .then((result) => {
                    data?.setLoad(true);
                    if (res?.id) {
                      data?.setLoad(true);
                      CustomFeesRepository.getCustomFeesSingle(
                        data?.child?.client_id,
                        res?.id
                      ).then((fees) => {
                        if (fees.lenght === 0) {
                          data?.setLoad(true);
                          data?.handleLoadDelete2();
                          if (res?.id) {
                            TeachrContactRepository.fetchTeachrContactByMail2(
                              res?.id,
                              data?.child?.mail
                            )
                              .then((resultat) => {
                                if (resultat.length === 0) {
                                  data?.handleDeleteTrue2(false);
                                  data?.setLoad(false);
                                  data?.handleLoadDelete2();
                                  data?.onValueChange2(false);
                                  Swal.fire({
                                    icon: "success",
                                    title: "Félicitations !",
                                    text: "Vous venez de supprimer cet élève de votre compte.",
                                    timer: 5000,
                                    willClose: () => {
                                      data?.navigate(
                                        "/teachr/dashboard/student"
                                      );
                                      data?.handleLoadDelete2();
                                      clearInterval(timerInterval);
                                    },
                                  });
                                } else {
                                  TeachrContactRepository.deleteTeachrContact(
                                    resultat.id
                                  )
                                    .then((err) => {
                                      data?.handleDeleteTrue2(false);
                                      data?.setLoad(false);
                                      data?.handleLoadDelete2();
                                      data?.onValueChange2(false);
                                      Swal.fire({
                                        icon: "success",
                                        title: "Félicitations !",
                                        text: "Vous venez de supprimer cet élève de votre compte.",
                                        timer: 5000,
                                        willClose: () => {
                                          clearInterval(timerInterval);
                                          data?.navigate(
                                            "/teachr/dashboard/student"
                                          );
                                        },
                                      });
                                    })
                                    .catch(() => {
                                      data?.setIsLoad2(false);
                                      data?.onValueChange2(false);
                                    });
                                }
                              })
                              .catch(() => {
                                data?.setIsLoad2(false);
                                data?.onValueChange2(false);
                              });
                          }
                        } else {
                          data?.handleLoadDelete2();
                          data?.setLoad(true);
                          if (fees) {
                            data?.setLoad(true);
                            data?.handleLoadDelete2();
                            if (res?.id) {
                              TeachrContactRepository.fetchTeachrContactByMail2(
                                res?.id,
                                data?.child?.mail
                              )
                                .then((resultat) => {
                                  data?.setLoad(true);
                                  if (resultat.length === 0) {
                                    CustomFeesRepository.deleteCustomFees(
                                      fees
                                    ).then((result) => { });
                                    data?.handleDeleteTrue2(false);
                                    data?.handleLoadDelete2();
                                    data?.setLoad(false);
                                    data?.onValueChange2(false);
                                    Swal.fire({
                                      icon: "success",
                                      title: "Félicitations !",
                                      text: "Vous venez de supprimer cet élève de votre compte.",
                                      timer: 5000,
                                      willClose: () => {
                                        data?.navigate(
                                          "/teachr/dashboard/student"
                                        );
                                        data?.handleLoadDelete2();
                                        clearInterval(timerInterval);
                                      },
                                    });
                                  } else {
                                    TeachrContactRepository.deleteTeachrContact(
                                      resultat.id
                                    )
                                      .then((err) => {
                                        CustomFeesRepository.deleteCustomFees(
                                          fees
                                        ).then((result) => { });
                                        data?.handleDeleteTrue2(false);
                                        data?.handleLoadDelete2();
                                        data?.setLoad(false);
                                        data?.onValueChange2(false);
                                        Swal.fire({
                                          icon: "success",
                                          title: "Félicitations !",
                                          text: "Vous venez de supprimer cet élève de votre compte.",
                                          timer: 5000,
                                          willClose: () => {
                                            clearInterval(timerInterval);
                                            data?.navigate(
                                              "/teachr/dashboard/student"
                                            );
                                          },
                                        });
                                      })
                                      .catch(() => {
                                        data?.setIsLoad2(false);
                                        data?.onValueChange2(false);
                                        data?.setLoad(false);
                                      });
                                  }
                                })
                                .catch(() => {
                                  data?.setIsLoad2(false);
                                  data?.onValueChange2(false);
                                  data?.setLoad(false);
                                });
                            }
                          }
                        }
                      });
                    }
                  })
                  .catch(() => {
                    data?.setIsLoad2(false);
                    data?.onValueChange2(false);
                    data?.setLoad(false);
                  });
              }
            });
          }
        }
      })}
    </>
  );
};
const handleArchivage = (data: dataArchiveModel) => {
  return (
    <>
      {Swal.fire({
        title: "Voulez-vous archiver cet élève ?",
        text: "En choisissant d'archiver cet élève, celui-ci sera retiré de votre liste active d'élèves. Il ne sera pas visible lors des déclarations de cours. Si vous souhaitez à nouveau déclarer des cours pour cet élève, il vous suffira de le désarchiver.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#4d4d4d",
        confirmButtonText: "Oui, archiver !",
        cancelButtonText: "Annuler",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          data?.setLoad(true);
          CustomFeesRepository.archives_client_by_teachr(
            data?.child?.custom_fees_id
          )
            .then((res) => {
              data?.handleDeleteTrue2(false);
              data?.setLoad(false);
              data?.handleLoadDelete2();
              data?.onValueChange2(false);
              Swal.fire({
                icon: "success",
                title: "Félicitations !",
                text: "Vous venez d'archiver cet élève, vous pouvez le retrouver dans la liste des élèves archivés.",
                timer: 5000,
                willClose: () => {
                  data?.navigate("/teachr/dashboard/student");
                  data?.handleLoadDelete2();
                  data?.onValueChange2(false);
                  clearInterval(timerInterval);
                },
              });
            })
            .catch((err) => {
              console.warn(err);
              data?.setLoad(false);
              data?.onValueChange2(false);
              Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Erreur inconnu, veuillez contacter le service client",
                timer: 5000,
                willClose: () => {
                  data?.navigate("/teachr/dashboard/student");
                  data?.handleLoadDelete2();

                  clearInterval(timerInterval);
                },
              });
            });
        }
      })}
    </>
  );
};
const handleUnArchivage = (data: dataArchiveModel) => {
  return (
    <>
      {Swal.fire({
        title: "Voulez-vous désarchiver cet élève ?",
        text: "En choisissant désarchiver cet élève, celui-ci sera retiré de votre liste archvés d'élèves. Il ne sera pas visible lors des déclarations de cours. Si vous souhaitez à nouveau déclarer des cours pour cet élève, il vous suffira de le désarchiver.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#4d4d4d",
        confirmButtonText: "Oui, désarchiver !",
        cancelButtonText: "Annuler",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          data?.setLoad(true);
          CustomFeesRepository.remove_archives_client_by_teachr(
            data?.child?.custom_fees_id
          )
            .then((res) => {
              data?.handleDeleteTrue2(false);
              data?.setLoad(false);
              data?.handleLoadDelete2();
              data?.onValueChange2(false);
              Swal.fire({
                icon: "success",
                title: "Félicitations !",
                text: "Vous venez d'archiver cet élève, vous pouvez le retrouver dans la liste des élèves archivés.",
                timer: 5000,
                willClose: () => {
                  data?.navigate("/teachr/dashboard/student");
                  data?.handleLoadDelete2();
                  data?.onValueChange2(false);
                  clearInterval(timerInterval);
                },
              });
            })
            .catch((err) => {
              console.warn(err);
              data?.setLoad(false);
              data?.onValueChange2(false);
              Swal.fire({
                icon: "error",
                title: "Erreur",
                text: "Erreur inconnu, veuillez contacter le service client",
                timer: 5000,
                willClose: () => {
                  data?.navigate("/teachr/dashboard/student");
                  data?.handleLoadDelete2();

                  clearInterval(timerInterval);
                },
              });
            });
        }
      })}
    </>
  );
};
export const buttonDelete = (data: btnDeleteModel) => {
  return (
    <div className="flex gap-x-1">
      {/* <Tooltip title="Supprimer" className="font-nunito"> */}
      <DeleteTwoToneIcon
        className="text-dark_blue cursor-pointer md:block hidden"
        onClick={() => displayeDelete(data?.data)}
      />
      {/* </Tooltip> */}
      <button className="/md:hidden" onClick={() => displayeDelete(data?.data)}>
        Supprimer
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data?.load}
      >
        <CircularProgress color="inherit" /> pfpf
      </Backdrop>
    </div>
  );
};
export const buttonArchivage = (data: btnArchiveModel) => {
  return (
    <div className="flex gap-x-1">
      {/* <Tooltip title="Archiver" className="font-nunito"> */}
      <ArchiveTwoToneIcon
        className={`${data?.firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
          } md:block hidden cursor-pointer`}
        onClick={() => handleArchivage(data?.data)}
      />
      {/* </Tooltip> */}
      <button
        className="/md:hidden"
        onClick={() => handleArchivage(data?.data)}
      >
        Archiver
      </button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data?.load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export const buttonUnArchivage = (data: btnArchiveModel) => {
  return (
    <div className="">
      <Tooltip title="Désarchiver">
        <UnarchiveTwoToneIcon
          className={`${data?.firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
            }  cursor-pointer`}
          onClick={() => handleUnArchivage(data?.data)}
        />
      </Tooltip>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data?.load}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export const buttonDelete2 = (data: btnDeleteModel) => {
  return (
    <div className="">
      <Tooltip title="Supprimer" className="font-nunito">
        <DeleteTwoToneIcon
          className={`${data?.firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
            }  cursor-pointer`}
          onClick={() => displayeDelete(data?.data)}
        />
      </Tooltip>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data?.load}
      >
        <CircularProgress color="inherit" /> pfpf
      </Backdrop>
    </div>
  );
};
export const BpIcon2 = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
export const BpCheckedIcon = styled(BpIcon2)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});
export const displayDialog = (
  course: any,
  title: string,
  text: string,
  icon: any,
  confirmText: string,
  cancelText: string,
  handleSubmit: (state: any) => void,
  color: string
) => {
  return (
    //red = "#d33"
    <>
      {Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: color,
        cancelButtonColor: "#4d4d4d",
        confirmButtonText: confirmText,
        cancelButtonText: cancelText,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          handleSubmit(course);
        }
      })}
    </>
  );
};
export const showStatus = (course: StatusCourse) => {
  return (
    <>
      {course?.urssafServiceStatus === null ? (
        <LightTooltipCours title={status.STATUS_NULL.message}>
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {course?.isOver
                ? status.STATUS_NULL.response2
                : status.STATUS_NULL.response}
            </span>
          </span>
        </LightTooltipCours>
      ) : course?.urssafServiceStatus === status.STATUS_INTEGRATED.status ||
        course?.urssafServiceStatus ===
        status.STATUS_WAITING_FOR_VALIDATION.status ||
        course?.urssafServiceStatus === status.STATUS_INTEGRATED.value ? (
        <LightTooltipCours
          title={
            course?.urssafServiceStatus === status.STATUS_INTEGRATED.status
              ? status.STATUS_INTEGRATED.message
              : status.STATUS_WAITING_FOR_VALIDATION.message
          }
        >
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_INTEGRATED.response}
            </span>
          </span>
        </LightTooltipCours>
      ) : course?.urssafServiceStatus === status.STATUS_VALIDATED.status ? (
        <LightTooltipPaye title="">
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_VALIDATED.response}
            </span>
          </span>
        </LightTooltipPaye>
      ) : course?.urssafServiceStatus === status.STATUS_PAID.status ? (
        <LightTooltipPaye title={status.STATUS_PAID.message}>
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-green-200 opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_PAID.response}
            </span>
          </span>
        </LightTooltipPaye>
      ) : course?.urssafServiceStatus === status.STATUS_COLLECTED.status ? (
        <LightTooltipPaye title={status.STATUS_COLLECTED.message}>
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_COLLECTED.response}
            </span>
          </span>
        </LightTooltipPaye>
      ) : course?.urssafServiceStatus === status.STATUS_COVERED.status ? (
        <LightTooltipPaye title={status.STATUS_COVERED.message}>
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_COVERED.response}
            </span>
          </span>
        </LightTooltipPaye>
      ) : course?.urssafServiceStatus === status.STATUS_CANCELED.status ||
        course?.urssafServiceStatus === status.STATUS_CANCELED_111.status ||
        course?.urssafServiceStatus === status.STATUS_CANCELED_112.status ? (
        <LightTooltipNoError
          title={
            course?.urssafServiceStatus === status.STATUS_CANCELED.status
              ? status.STATUS_CANCELED.message
              : course?.urssafServiceStatus ===
                status.STATUS_CANCELED_111.status
                ? status.STATUS_CANCELED_111.message
                : status.STATUS_CANCELED_112.message
          }
        >
          <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
            <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_CANCELED.response}
            </span>
          </span>
        </LightTooltipNoError>
      ) : course?.urssafServiceStatus === status.STATUS_REFUSED.status ||
        course?.urssafServiceStatus ===
        status.STATUS_REFUSAL_OF_DIRECT_DEBIT.status ? (
        <LightTooltipNoError
          title={
            course?.urssafServiceStatus === status.STATUS_REFUSED.status
              ? status.STATUS_CANCELED_112.message
              : status.STATUS_REFUSAL_OF_DIRECT_DEBIT.message
          }
        >
          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
            <span className="absolute inset-0 bg-[#FFE0D9] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_REFUSED.response}
            </span>
          </span>
        </LightTooltipNoError>
      ) : (
        <LightTooltipNoError
          title={
            status.STATUS_ERROR.message + " : " + course?.urssafServiceStatus
          }
        >
          <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
            <span className="absolute inset-0 bg-[#FFE0D9] opacity-50 rounded-full" />
            <span className="relative text-xs">
              {status.STATUS_ERROR.response}
            </span>
          </span>
        </LightTooltipNoError>
      )}
    </>
  );
};
export const months = [
  ["Janvier", "j"], //[["name","shortName"], ... ]
  ["Février", "f"],
  ["Mars", "m"],
  ["Avril", "a"],
  ["Mai", "m"],
  ["Juin", "j"],
  ["Juillet", "j"],
  ["Août", "a"],
  ["Septembre", "s"],
  ["Octobre", "o"],
  ["Novembre", "n"],
  ["Décembre", "d"],
];
export const weekDays = [
  ["Dimanche", "D"],
  ["Lundi", "L"],
  ["Mardi", "M"],
  ["Mercredi", "M"],
  ["Jeudi", "J"],
  ["Vendredi", "V"],
  ["Samedi", "S"],
];
export const validatePrice = (value: any, duration: any) => {
  if (value === undefined || value === "" || value === null || isNaN(value)) {
    return {
      isValid: false,
      message: "Entrez le prix",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
    };
  } else if (value < 11) {
    return {
      isValid: false,
      message:
        "Le prix par heure payé par votre client ne doit pas être inférieur à 11€",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
    };
  } else if (value > 138) {
    return {
      isValid: false,
      message:
        "Le prix par heure payé par votre client ne doit pas dépasser 138€",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
    };
  } else if (value === 0) {
    return {
      isValid: false,
      message: "Veuillez entrer le prix",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
    };
  } else if (Functions.priceTotalTTC(value * duration) > 1000) {
    // console.log(Functions.priceTotalTTC(value * duration), value, duration);
    return {
      isValid: false,
      message:
        "Le montant facturé de la prestation de votre élève ne doit pas depasser 1000€. Sur le cours que vous êtes en train d'enregistrer, le montant facturé est de " +
        Functions.priceTotalTTC(value * duration) +
        "€",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};
export const validateChild = (value: any) => {
  if (!value) {
    return {
      isValid: false,
      message: "Sélectionnez un élève",
      //   icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
    };
  } else
    return {
      isValid: true,
      message: "",
      //   icon: "",
    };
};
export const LightTooltipNoError = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F2F9FF",
    // backgroundColor: "#FF724F",
    color: "#FF724F",
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontFamily: "Nunito",
  },
}));
export const LightTooltipPaye = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "#4d4d4d",
    boxShadow: "7px 5px 20px  #E8FFEF",
    border: "1px solid  #E8FFEF",
    fontSize: 14,
    fontFamily: "Nunito",
  },
}));
export const LightTooltipCours = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "#4d4d4d",
    boxShadow: "7px 5px 20px  #F4F4F8",
    border: "1px solid  #F4F4F8",
    fontSize: 14,
    fontFamily: "Nunito",
  },
}));
export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#F2F9FF",
    color: "#4d4d4d",
    boxShadow: theme.shadows[1],
    fontSize: 10,
    fontFamily: "Nunito",
  },
}));
export const showStatutStudent = (student: any, load: boolean) => {
  if (student?.status_tdp) {
    if (student?.etat_tdp !== statusTpdEnum.VALIDE.etat) {
      return (
        <LightTooltipPaye title={statusTpdEnum.VALIDE.value}>
          <div className="px-4 py-1 md:w-2/3 w-full :border-2 text-center bg-[#F4F4F8] rounded-full :py-1 font-semibold text-[#4d4d4d] /leading-tight">
            <span className="text-sm text-center">
              {load && student?.id ? (
                <CircularProgress
                  sx={{ width: "20px !important", height: "20px !important" }}
                />
              ) : (
                statusTpdEnum.VALIDE.response
              )}
            </span>
          </div>
        </LightTooltipPaye>
      );
    } else {
      switch (student?.code_tdp) {
        case statusTpdEnum.EN_COURS.status:
          return (
            <LightTooltipPaye title={statusTpdEnum.EN_COURS.value}>
              <div className="px-4 py-1 md:w-2/3 w-full :border-2 text-center bg-[#F4F4F8] rounded-full /py-1 font-semibold text-[#4d4d4d] /leading-tight">
                <span className="text-sm text-center">
                  {load && student?.id ? (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    statusTpdEnum.EN_COURS.response
                  )}
                </span>
              </div>
            </LightTooltipPaye>
          );
          break;
        case statusTpdEnum.EN_ATTENTE.status:
          return (
            <LightTooltipPaye title={statusTpdEnum.EN_ATTENTE.value}>
              <div className="px-4 py-1 md:w-2/3 w-full :border-2 text-center bg-[#F7BE6D] rounded-full /py-1 font-semibold text-[#4d4d4d] /leading-tight">
                <span className="text-sm text-center">
                  {load && student?.id ? (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    statusTpdEnum.EN_ATTENTE.response
                  )}
                </span>
              </div>
            </LightTooltipPaye>
          );
          break;
        case statusTpdEnum.BLOQUE.status:
          return (
            <LightTooltipPaye title={statusTpdEnum.BLOQUE.value}>
              <div className="px-4 py-1 md:w-2/3 w-full :border-2 text-center bg-[#FFE0D9] rounded-full /py-1 font-semibold text-[#4d4d4d] /leading-tight">
                <span className="text-sm text-center">
                  {load && student?.id ? (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    statusTpdEnum.BLOQUE.response
                  )}
                </span>
              </div>
            </LightTooltipPaye>
          );
          break;

        default:
          return (
            <LightTooltipPaye title={"En attente"}>
              <div className="px-4 py-1 md:w-2/3 w-full :border-2 text-center bg-[#F4F4F8] rounded-full /py-1 font-semibold text-[#4d4d4d] /leading-tight">
                <span className="text-sm text-center">
                  {load && student?.id ? (
                    <CircularProgress
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  ) : (
                    "En attente"
                  )}
                </span>
              </div>
            </LightTooltipPaye>
          );
          break;
      }
    }
  }
};
export const removeExtraSpace = (s: any) => s.trim().split(/ +/).join("");
//Function to extract date and time from a string
export function extractDateTimeFromString(text: any) {
  // Utilisation d'une expression régulière pour extraire la date et l'heure
  // Le pattern correspond à DD/MM/YYYY HH:MM:SS
  const regex = /(\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2})/;
  const match = text?.match(regex);

  // Si un match est trouvé, renvoie la date et l'heure
  if (match) {
    return match[0];
  }

  // Sinon, renvoie une chaîne vide ou null pour indiquer qu'aucune date n'a été trouvéef
  return null;
}
//Function to convert date and time from DD/MM/YYYY HH:MM:SS to ISO format
export function convertDateTimeFormat(dateTimeStr: any) {
  if (dateTimeStr) {
    const [date, time] = dateTimeStr?.split(" ");
    const [day, month, year] = date?.split("/");
    const isoFormattedStr = `${year}-${month}-${day}T${time}`;

    // Création de l'objet Date
    const dateObj = new Date(isoFormattedStr);

    return dateObj;
  }
}
export const days = [
  {
    fr: "Lundi",
    en: "monday",
  },
  {
    fr: "Mardi",
    en: "tuesday",
  },
  {
    fr: "Mercredi",
    en: "wednesday",
  },
  {
    fr: "Jeudi",
    en: "thursday",
  },
  {
    fr: "Vendredi",
    en: "friday",
  },
  {
    fr: "Samedi",
    en: "saturday",
  },
  {
    fr: "Dimanche",
    en: "sunday",
  },
];
export const translateDay = (day: string) => {
  const translate = days.filter(
    (item: any) => item.en.toLowerCase() === day.toLowerCase()
  );
  if (translate.length >= 0) {
    return translate[0].fr;
  }
  return "";
};
export const acceptCourse = (teachr: Teachr, navigate: any) => {
  let isBornFrance = false;
  let nni = "";
  let secondName = "";
  let postalCode = "";
  let defaultAddress: Address | null = null;
  let iban = "";

  const getPlaceId = (placeId: string) => {
    geocodeByPlaceId(placeId).then((response) => {
      const addressObject = new Address(
        response[0].place_id,
        response[0].address_components[0].long_name,
        response[0].address_components[1].long_name,
        response[0].address_components[2].long_name,
        response[0].address_components[3].long_name,
        response[0].address_components[4].long_name,
        response[0].address_components[5].long_name,
        response[0].address_components[6].long_name,
        response[0].geometry.location.lat().toString(),
        "",
        response[0].formatted_address,
        teachr?.["@id"]!,
        true
      );
      addressObject.sessionToken = "";
      defaultAddress = addressObject;
    });
  };
  const getPostal = (value: string) => {
    postalCode = value;
  };
  const getNni = (value: string) => {
    nni = value.replace(/\s/g, "");
  };
  const getSecondName = (value: string) => {
    secondName = value;
  };
  const validate = () => {
    let errAddress = true;
    let errZipCode = true;
    let errNni = true;
    let errIban = true;
    const errorsTag = document.getElementsByClassName("errors");
    if (defaultAddress) {
      errAddress = false;
      errorsTag[0].innerHTML = "";
    } else {
      errAddress = true;
      errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
    }
    if (validateZipCode(postalCode).isValid) {
      errZipCode = false;
      errorsTag[1].innerHTML = validateZipCode(postalCode).message;
    } else {
      errZipCode = true;
      errorsTag[1].innerHTML = validateZipCode(postalCode).message;
    }
    if (ValidateNni(nni).isValid) {
      errNni = false;
      errorsTag[2].innerHTML = ValidateNni(nni).message;
    } else {
      errNni = true;
      errorsTag[2].innerHTML = ValidateNni(nni).message;
    }
    if (ValidateIban(iban).isValid) {
      errorsTag[3].innerHTML = ValidateIban(iban).message;
      errIban = false;
    } else {
      errorsTag[3].innerHTML = ValidateIban(iban).message;
      errIban = true;
    }
    switch (teachr?.isAE) {
      case false:
        if (teachr?.address !== null) {
          if (teachr?.iban == null) {
            if (errZipCode || errNni || errIban) {
              return false;
            } else {
              return true;
            }
          } else {
            if (errZipCode || errNni) {
              return false;
            } else {
              return true;
            }
          }
        } else {
          if (teachr?.iban == null) {
            if (errAddress || errZipCode || errNni || errIban) {
              return false;
            } else {
              return true;
            }
          } else {
            if (errAddress || errZipCode || errNni) {
              return false;
            } else {
              return true;
            }
          }
        }

      default:
        if (errAddress) {
          return false;
        } else {
          return true;
        }
    }
  };
  const createAddress = () => {
    let submitError = true;
    AddressRepository.createAddress(defaultAddress!)
      .then(() => {
        submitError = true;
        return true;
      })
      .catch((error) => {
        submitError = false;
        return false;
      });
    return submitError;
  };
  const updateTeachr = () => {
    let submitError = true;
    const newTeachr = teachr!;
    if (teachr && teachr.iban == null) {
      newTeachr.iban = iban.replace(/ /g, "");
    }
    newTeachr.NNI = nni;
    newTeachr.bornPlace = postalCode;
    newTeachr.secondFirstName = secondName;
    newTeachr.isBornFrance = isBornFrance;
    if (teachr && teachr.proofIdentity) {
      newTeachr.proofIdentity = teachr.proofIdentity["@id"];
    }
    if (teachr && teachr.proofIsAE) {
      newTeachr.proofIsAE = teachr.proofIsAE["@id"];
    }
    if (teachr && teachr.proofSignSap) {
      newTeachr.proofSignSap = teachr.proofSignSap["@id"];
    }
    if (teachr && teachr.proofMandat) {
      newTeachr.proofMandat = teachr.proofMandat["@id"];
    }
    TeachrRepository.updateTeachr(newTeachr!).catch((error) => {
      submitError = false;
    });
    return submitError;
  };
  switch (teachr?.isAE) {
    case true:
      if (teachr?.address !== null) {
        if (teachr && teachr.iban == null) {
          MySwal.fire({
            showConfirmButton: false,
            html: (
              <div>
                <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                  <p>Renseignements supplémentaires</p>
                  <img
                    onClick={() => Swal.close()}
                    className="cursor-pointer"
                    src={closeCircle}
                    alt=""
                  />
                </div>
                <div className="py-8 text-left ">
                  <p className="text-sm text-normal">
                    Pour déclarer au cours, merci de remplir les informations
                    suivantes afin de pouvoir effectuer les démarches de
                    déclaration auprès de l’URSSAF. Une fois complétées, vous
                    pourrPour déclarer vos course cours que vous souhaitez !
                  </p>
                  <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                    <div className={teachr.iban ? "hidden" : ""}>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Iban</p>
                        <input
                          id="iban-input"
                          type="text"
                          className="block w-full bg-transparent border-none focus:outline-none"
                          onChange={(e) => (iban = e.target.value)}
                          placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-4 lg:pt-8">
                    <Button
                      onClick={() => Swal.clickConfirm()}
                      className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            ),
            preConfirm: () => {
              const errorsTag = document.getElementsByClassName("errors");
              if (ValidateIban(iban).isValid) {
                errorsTag[0].innerHTML = ValidateIban(iban).message;
                teachr.iban = iban.replace(/ /g, "");
                if (teachr && teachr.proofIdentity) {
                  teachr.proofIdentity = teachr.proofIdentity["@id"];
                }
                if (teachr && teachr.proofIsAE) {
                  teachr.proofIsAE = teachr.proofIsAE["@id"];
                }
                if (teachr && teachr.proofSignSap) {
                  teachr.proofSignSap = teachr.proofSignSap["@id"];
                }
                if (teachr && teachr.proofMandat) {
                  teachr.proofMandat = teachr.proofMandat["@id"];
                }
                TeachrRepository.updateTeachr(teachr).catch(() => {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                });
              } else {
                errorsTag[0].innerHTML = ValidateIban(iban).message;
              }
            },
          }).then(
            (result) =>
              result.isConfirmed && navigate("/teachr/dashboard/declare")
          );
          MySwal.getPopup()?.classList.add("shadow-candidature-close");
          MySwal.getPopup()?.classList.add("w-[698px]");
          MySwal.getPopup()?.classList.add("rounded-[20px]");
        } else {
          navigate("/teachr/dashboard/declare");
        }
      } else if (teachr && teachr.iban == null) {
        MySwal.fire({
          showConfirmButton: false,
          html: (
            <div>
              <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                <p>Renseignements supplémentaires</p>
                <img
                  onClick={() => Swal.close()}
                  className="cursor-pointer"
                  src={closeCircle}
                  alt=""
                />
              </div>
              <div className="py-8 text-left ">
                <p className="text-sm text-normal">
                  Pour déclarer au cours, merci de remplir les informations
                  suivantes afin de pouvoir effectuer les démarches de
                  déclaration auprès de l’URSSAF. Une fois complétées, vous
                  pourrPour déclarer vos course cours que vous souhaitez !
                </p>
                <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                  <div>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Adresse postale</p>
                      <AddressComponent getPlaceId={getPlaceId} />
                    </div>
                  </div>
                  <div className={teachr.iban ? "hidden" : ""}>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Iban</p>
                      <input
                        id="iban-input"
                        type="text"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        onChange={(e) =>
                          (iban = e.target.value.replace(/ /g, ""))
                        }
                        placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 lg:pt-8">
                  <Button
                    onClick={() => Swal.clickConfirm()}
                    className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          ),
          preConfirm: () => {
            const errorsTag = document.getElementsByClassName("errors");
            let errAddress = true;
            let errIban = true;
            if (defaultAddress) {
              errAddress = false;
              errorsTag[0].innerHTML = "";
            } else {
              errAddress = true;
              errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
            }
            if (ValidateIban(iban).isValid) {
              errorsTag[1].innerHTML = ValidateIban(iban).message;
              errIban = false;
            } else {
              errorsTag[1].innerHTML = ValidateIban(iban).message;
              errIban = true;
            }
            if (!errAddress && !errIban) {
              if (!createAddress()) {
                return Swal.showValidationMessage(
                  "Erreur : Quelque chose s'est mal passé"
                );
              } else {
                teachr.iban = iban.replace(/ /g, "");
                if (teachr && teachr.proofIdentity) {
                  teachr.proofIdentity = teachr.proofIdentity["@id"];
                }
                if (teachr && teachr.proofIsAE) {
                  teachr.proofIsAE = teachr.proofIsAE["@id"];
                }
                if (teachr && teachr.proofSignSap) {
                  teachr.proofSignSap = teachr.proofSignSap["@id"];
                }
                if (teachr && teachr.proofMandat) {
                  teachr.proofMandat = teachr.proofMandat["@id"];
                }
                TeachrRepository.updateTeachr(teachr).catch(() => {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                });
              }
            } else {
            }
          },
        }).then(
          (result) =>
            result.isConfirmed && navigate("/teachr/dashboard/declare")
        );
        MySwal.getPopup()?.classList.add("shadow-candidature-close");
        MySwal.getPopup()?.classList.add("w-[698px]");
        MySwal.getPopup()?.classList.add("rounded-[20px]");
      } else {
        MySwal.fire({
          showConfirmButton: false,
          html: (
            <div>
              <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                <p>Renseignements supplémentaires</p>
                <img
                  onClick={() => Swal.close()}
                  className="cursor-pointer"
                  src={closeCircle}
                  alt=""
                />
              </div>
              <div className="py-8 text-left ">
                <p className="text-sm text-normal">
                  Pour déclarer au cours, merci de remplir les informations
                  suivantes afin de pouvoir effectuer les démarches de
                  déclaration auprès de l’URSSAF. Une fois complétées, vous
                  pourrPour déclarer vos course cours que vous souhaitez !
                </p>
                <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                  <div>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Adresse postale</p>
                      <AddressComponent getPlaceId={getPlaceId} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 lg:pt-8">
                  <Button
                    onClick={() => Swal.clickConfirm()}
                    className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          ),
          preConfirm: () => {
            const errorsTag = document.getElementsByClassName("errors");
            if (defaultAddress) {
              errorsTag[0].innerHTML = "";
              if (!createAddress()) {
                return Swal.showValidationMessage(
                  "Erreur : Quelque chose s'est mal passé"
                );
              }
            } else {
              errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
            }
          },
        }).then(
          (result) =>
            result.isConfirmed && navigate("/teachr/dashboard/declare")
        );
        MySwal.getPopup()?.classList.add("shadow-candidature-close");
        MySwal.getPopup()?.classList.add("w-[698px]");
        MySwal.getPopup()?.classList.add("rounded-[20px]");
      }
      break;

    default:
      if (teachr?.isBornFrance === null && teachr?.NNI === null) {
        MySwal.fire({
          showConfirmButton: false,
          html: (
            <div>
              <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                <p>Renseignements supplémentaires</p>
                <img
                  onClick={() => Swal.close()}
                  className="cursor-pointer"
                  src={closeCircle}
                  alt=""
                />
              </div>
              <div className="py-8">
                <p className="w-full text-sm font-bold text-third">
                  NB : Ces informations servent à ce que vos cours soient
                  déclarés par nos soins. Nous ne sommes PAS en train de vous
                  créer votre statut auto-entrepreneur.{" "}
                </p>
              </div>
              <div className="py-8 text-left ">
                <p className="text-sm text-normal">
                  Pour déclarer au cours, merci de remplir les informations
                  suivantes afin de pouvoir effectuer les démarches de
                  déclaration auprès de l’URSSAF. Une fois complétées, vous
                  pourrPour déclarer vos course cours que vous souhaitez !
                </p>
                <p className="py-1 text-sm text-normal">
                  PS : si vous avez le statut auto-entrepreneur, cette étape
                  n’est pas nécessaire.
                  <a
                    href="https://drive.google.com/drive/folders/1ua_E5nxAJQzB8NIE4E-4fI6hkwD_SS0e?usp=sharing"
                    className="ml-1 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cliquez ici
                  </a>{" "}
                  pour en savoir plus et vous créer le statut 😉
                </p>
                <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                  <FormControl>
                    <div className="flex items-center justify-between">
                      <label className="text-sm font-extrabold text-first lg:text-base">
                        Êtes-vous né(e) en France ?{" "}
                      </label>
                      <RadioGroup row defaultValue="1" name="customized-radios">
                        <FormControlLabel
                          value="1"
                          onClick={() => (isBornFrance = true)}
                          control={
                            <RadioButton
                              border="1px solid #0054A3"
                              bg="#0054A3"
                            />
                          }
                          label="Oui"
                        />
                        <FormControlLabel
                          value="0"
                          onClick={() => (isBornFrance = false)}
                          control={
                            <RadioButton
                              border="1px solid #0054A3"
                              bg="#0054A3"
                            />
                          }
                          label="Non"
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>
                  <div className={teachr.address ? "hidden" : ""}>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Adresse postale</p>
                      <AddressComponent getPlaceId={getPlaceId} />
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-red-600 errors"></p>
                    <Input
                      callback={getPostal}
                      label="Code postal de naissance"
                      inset="true"
                      placeholder="Ex : 75013"
                      customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                    />
                  </div>
                  <div>
                    <p className="text-sm text-red-600 errors"></p>
                    <Input
                      callback={getNni}
                      label="Numéro de sécurité sociale"
                      inset="true"
                      placeholder="Ex : 1 97 12 83 232 428 48"
                      customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                    />
                  </div>
                  <div className={teachr.iban ? "hidden" : ""}>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Iban</p>
                      <input
                        id="iban-input"
                        type="text"
                        className="block w-full bg-transparent border-none focus:outline-none"
                        onChange={(e) =>
                          (iban = e.target.value.replace(/ /g, ""))
                        }
                        placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                      />
                    </div>
                  </div>
                  <div>
                    <Input
                      callback={getSecondName}
                      label="Deuxième prénom (Facultatif)"
                      inset="true"
                      placeholder="Ex : Hélène"
                      customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                    />
                  </div>
                </div>
                <div className="flex justify-end pt-4 lg:pt-8">
                  <Button
                    onClick={() => Swal.clickConfirm()}
                    className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          ),
          preConfirm: () => {
            if (validate()) {
              if (!teachr.address) {
                if (createAddress()) {
                  if (!updateTeachr()) {
                    return Swal.showValidationMessage(
                      "Erreur : Quelque chose s'est mal passé"
                    );
                  }
                }
              } else {
                if (!updateTeachr()) {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                }
              }
            } else {
            }
          },
        }).then(
          (result) =>
            result.isConfirmed && navigate("/teachr/dashboard/declare")
        );
        MySwal.getPopup()?.classList.add("shadow-candidature-close");
        MySwal.getPopup()?.classList.add("w-[698px]");
        MySwal.getPopup()?.classList.add("rounded-[20px]");
      } else if (teachr?.address) {
        navigate("/teachr/dashboard/declare");
      } else {
        MySwal.fire({
          showConfirmButton: false,
          html: (
            <div>
              <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                <p>Renseignements supplémentaires</p>
                <img
                  onClick={() => Swal.close()}
                  className="cursor-pointer"
                  src={closeCircle}
                  alt=""
                />
              </div>
              <div className="py-8">
                <p className="w-full text-sm font-bold text-third">
                  NB : Ces informations servent à ce que vos cours soient
                  déclarés par nos soins. Nous ne sommes PAS en train de vous
                  créer votre statut auto-entrepreneur.{" "}
                </p>
              </div>
              <div className="py-8 text-left ">
                <p className="text-sm text-normal">
                  Pour déclarer au cours, merci de remplir les informations
                  suivantes afin de pouvoir effectuer les démarches de
                  déclaration auprès de l’URSSAF. Une fois complétées, vous
                  pourrPour déclarer vos course cours que vous souhaitez !
                </p>

                <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                  <div>
                    <p className="text-sm text-red-600 errors"></p>
                    <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                      <p className="mx-2">Adresse postale</p>
                      <AddressComponent getPlaceId={getPlaceId} />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end pt-4 lg:pt-8">
                  <Button
                    onClick={() => Swal.clickConfirm()}
                    className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                  >
                    Valider
                  </Button>
                </div>
              </div>
            </div>
          ),
          preConfirm: () => {
            const errorsTag = document.getElementsByClassName("errors");
            if (defaultAddress) {
              errorsTag[0].innerHTML = "";
              if (!createAddress()) {
                return Swal.showValidationMessage(
                  "Erreur : Quelque chose s'est mal passé"
                );
              }
            } else {
              errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
            }
          },
        }).then(
          (result) =>
            result.isConfirmed && navigate("/teachr/dashboard/declare")
        );
        MySwal.getPopup()?.classList.add("shadow-candidature-close");
        MySwal.getPopup()?.classList.add("w-[698px]");
        MySwal.getPopup()?.classList.add("rounded-[20px]");
      }
      break;
  }
};
export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return ""; // Ou un comportement par défaut selon vos besoins
  }
};
