// import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import ResponseCourse from "./ResponseCourse";
import { styled } from "@mui/material/styles";
import NavBarCominsoon from "../../../Components/Navbar/NavBarCominsoon";
import ActiveCourse from "./ActiveCourse";
// import HistoriqueComingsoon from "./Components/HistoriqueComingsoon";
import { auth, getJWTToken } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import NoLessonRequest from "./Components/NoLessonRequest";
import NoActiveCourse from "./Components/NoActiveCourse";
import HistoricCourse from "./HistoricCourse";
import NoHistoriqueCourse from "./Components/NoHistoriqueCourse";
import ApiService from "../../../Services/ApiService";
import TeachrRepository from "../../../Repositories/TeachrRepository";
import { useNavigate } from "react-router-dom";
import ClientRepository from "../../../Repositories/ClientRepository";
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF724F",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #FF724F",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0054A3",
  "&.Mui-selected": {
    color: "#FF724F",
  },
}));

export default function BasicTabs() {
  const [user] = useAuthState(auth);
  const [value, setValue] = useState(0);
  const navigate = useNavigate()
  // const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (user) {
        (async () => {
            // Swal.close()
            const jwtToken = await getJWTToken(user)
            ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
            TeachrRepository.getCurrentTeachr().then(() => navigate("/teachr/cours")).catch(() => {
                ApiService.disableAuth()
                ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
                ClientRepository.getCurrentClient().then((response) => {
                    navigate("/user/response")
                }).catch(() => {
                    ApiService.disableAuth()
                    navigate("/teachr/register/next")
                })

            })
        })()
    }
}, [user]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="h-screen overflow-x-hidden list-teacher font-nunito">
      <div className="fixed z-40 w-full bg-white">
        <NavBarCominsoon />
        <div className="mx-4 md:mx-10">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="hidden md:block">
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    Demande en attente
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    Cours prévus
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    Historique
                  </p>
                }
              />
            </StyledTabs>
            </div>
            <div className="-mt-10 md:hidden //border">
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    En attente
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    Cours prévus
                  </p>
                }
              />
              <StyledTab
                label={
                  <p className="text-sm font-bold lg:text-lg md:text-base">
                    Historique
                  </p>
                }
              />
            </StyledTabs>
            </div>
            
          </Box>
        </div>
      </div>
      <div className="md:mx-10  mt-[120px] ">
        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            {user ? <ResponseCourse /> : <NoLessonRequest />}   
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <ActiveCourse/> */}
            {user ?  <ActiveCourse/> : <NoActiveCourse />}  
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <HistoriqueComingsoon/> */}
            {user ?  <HistoricCourse/> : <NoHistoriqueCourse />}  
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <HistoriqueComingsoon/> */}
            {user ?  <HistoricCourse/> : <NoHistoriqueCourse />}  
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
